import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, CardMedia, Button, FormControl, InputLabel, TextField, Fade, Slide, Grow, Zoom, Popover, Box, Stack, Alert  } from '@mui/material';
import axios from 'axios';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSteam, faPlaystation, faXbox } from '@fortawesome/free-brands-svg-icons'
import DeleteIcon from '@mui/icons-material/Delete';
import { CreateLinkedAccount, DeleteLinkedAccount, clickOnAccount, getLinknedAccounts } from '../../utils/LinkedAccounts';

const Epic_Games_Icon = require('../../Assets/Epic_Games.png');

const LinkedAccounts = ({ Theme, userData, fail }) => {

   const [Platform, setPlatform] = useState("Epic_Games")
   const [State, setState] = useState("ListAccounts")
   const [Accounts, setAccounts] = useState([])

   async function HandleAccounts() {

    const data = await getLinknedAccounts(userData)

    const maped = await Promise.all(data.map(async(item, index) => {


        console.log(item)

        if(item.Platform === 'Steam') {

            if(!item.Username.includes('com/')) return {
                Platform: item.Platform,
                Username: item.Username,
                userID: item.userID,
                displayName: `An error has occured!`,
                _id: item._id
            }

            const Type = item.Username.split("com/")[1].split('/')[0];

            if(Type === 'profiles') {

                return {
                    Platform: item.Platform,
                    Username: item.Username,
                    userID: item.userID,
                    displayName: item.Username.split("/profiles/")[1].replace('/', '').replace('/', '').toUpperCase(),
                    _id: item._id
                }

            } else if(Type === 'id') {

                return {
                    Platform: item.Platform,
                    Username: item.Username,
                    userID: item.userID,
                    displayName: item.Username.split("/id/")[1].replace('/', '').replace('/', '').toUpperCase(),
                    _id: item._id
                }

            } else {

                return {
                    Platform: item.Platform,
                    Username: item.Username,
                    userID: item.userID,
                    displayName: 'An Error Has Occured!',
                    _id: item._id
                }

            }


        } else {

            return {
                Platform: item.Platform,
                Username: item.Username,
                userID: item.userID,
                displayName: item.Username,
                _id: item._id
            }

        }



    }))

    return setAccounts(maped)
    
   }

   async function CreateData(Username, Platform, userData) {

    const data = await CreateLinkedAccount(Username, Platform, userData)
    setState('ListAccounts')
    HandleAccounts(data)

   }

   async function DeleteData(Item, userData) {

    const data = await DeleteLinkedAccount(Item, userData);
    HandleAccounts(data)

   }

   useEffect(() => {

    HandleAccounts()

   }, [userData])

  return (
    <Box sx={{ height: '635px', width: { xs: '90vw', md: '50vw'}, borderRadius: '25px', overflow: 'hidden' }}>
        <Stack gap={2}>
            <Box sx={{ width: { xs: '90vw', md: '50vw'}, height: '50px', backgroundColor: '', borderTopRightRadius: '25px', borderTopLeftRadius: '25px', borderBottom: `5px solid ${Theme.BorderColor}`}}>
                <Stack direction="row" sx={{ width: { xs: '90vw', md: '50vw'}, height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{ backgroundColor: '', display: { xs: 'flex', md: 'none'} , ":hover": { cursor: 'pointer'}}} onClick={() => setState('AddAccountOptions')}>
                        <Stack direction="row" gap={1} sx={{ width: { xs: '90vw', md: '50vw'}, height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <AddSharpIcon sx={{ color: `#fff`}} /> <Typography sx={{ fontFamily: 'Impact', fontSize: '25px', color: `${Theme.TextColor}`}}>ADD ACCOUNT</Typography>
                        </Stack>
                    </Box>

                    <Box sx={{ backgroundColor: '', borderRight: `5px solid ${Theme.BorderColor}`, borderLeft: `5px solid ${Theme.BorderColor}`, ":hover": { backgroundColor: 'darkblue', cursor: 'pointer'}}} onClick={() => {
                        setPlatform('Epic_Games')
                        setState("AddAccount")
                    }}>
                        <Stack direction="row" gap={1} sx={{  width: '10vw', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', display: { xs: 'none', md: 'flex'}, overflow: 'clip'  }} >
                        <img src={Epic_Games_Icon} style={{ height: '25px' }} /><Typography sx={{ fontFamily: 'Impact', fontSize: '25px', color: `${Theme.TextColor}`, display: { xs: 'none', md: 'none', xl: 'flex'}}}>EPIC GAMES</Typography>
                        </Stack>
                    </Box>
                    <Box sx={{ backgroundColor: '', borderRight: `5px solid ${Theme.BorderColor}`, display: { xs: 'none', md: 'flex'}, ":hover": { backgroundColor: 'darkblue', cursor: 'pointer'} }}  onClick={() => {
                        setPlatform('Steam')
                        setState("AddAccount")
                    }}>
                        <Stack direction="row" gap={1} sx={{  width: '10vw', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', display: { xs: 'none', md: 'flex'}, overflow: 'clip'  }}>
                        <FontAwesomeIcon icon={faSteam} style={{ color: `#fff`, fontSize: '30px'}} /> <Typography sx={{ fontFamily: 'Impact', fontSize: '25px', color: `${Theme.TextColor}`, display: { xs: 'none', md: 'none', xl: 'flex'}}}>STEAM</Typography>
                        </Stack>
                    </Box>
                    <Box sx={{ backgroundColor: '', borderRight: `5px solid ${Theme.BorderColor}`, ":hover": { backgroundColor: 'darkblue', cursor: 'pointer'}}}  onClick={() => {
                        setPlatform('PlayStation')
                        setState("AddAccount")
                    }}>
                        <Stack direction="row" gap={1} sx={{  width: '10vw', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', display: { xs: 'none', md: 'flex'}, overflow: 'clip' }}>
                        <FontAwesomeIcon icon={faPlaystation} style={{ color: `#fff`, fontSize: '30px'}} /> <Typography sx={{ fontFamily: 'Impact', fontSize: '25px', color: `${Theme.TextColor}`, display: { xs: 'none', md: 'none', xl: 'flex'}}}>PLAYSTATION</Typography>
                        </Stack>
                    </Box>
                    <Box sx={{ backgroundColor: '', borderRight: `5px solid ${Theme.BorderColor}`, ":hover": { backgroundColor: 'darkblue', cursor: 'pointer'}}}  onClick={() => {
                        setPlatform('XBOX')
                        setState("AddAccount")
                    }}>
                        <Stack direction="row" gap={1} sx={{  width: '10vw', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', display: { xs: 'none', md: 'flex'}, overflow: 'clip'  }}>
                        <FontAwesomeIcon icon={faXbox} style={{ color: `#fff`, fontSize: '30px'}} /> <Typography sx={{ fontFamily: 'Impact', fontSize: '25px', color: `${Theme.TextColor}`, display: { xs: 'none', md: 'none', xl: 'flex'}}}>XBOX</Typography>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
            <Box sx={{ width: { xs: '90vw', md: '50vw'}, height: '545px', backgroundColor: '', borderBottomRightRadius: '25px', borderBottomLeftRadius: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

            {State === 'AddAccount' && 
            <Stack sx={{ width: { xs: '80vw', md: '50vw'}, height: '545px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grow in={true}>
                <Box sx={{ backgroundColor: 'black', width: '100%', height: '70px', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '30px'}}>
                  Add Your {Platform.replace('_', ' ')} Account
                </Box>
                </Grow>

                <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}> 
                <Box sx={{ paddingTop: '20px'}}>
                {Platform === 'Steam' && <Typography sx={{ fontSize: '18px', color: '#fff'}}>Please provide your Steam Profile URL</Typography>}
                {Platform != 'Steam' && <Typography sx={{ fontSize: '18px', color: '#fff'}}>Please provide the username for your {Platform.toLowerCase().replace('_', ' ')} account.</Typography>}
                <br />
                <input id="account-name" style={{ height: '60px', width: '100%', backgroundColor: 'black', outline: 'none', border: 'none', paddingLeft: '10px', fontSize: '20px', color: '#fff', outline: '1px solid #fff' }} placeholder={Platform === "Steam" ? 'Steam Profile URL' : 'Username'} type="name" />
                </Box>
                </Grow>

                <Slide direction="up" in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
                <Box>
                <table>
                <th>
                <Box onClick={() => {

                if(document.getElementById('account-name').value === "") {
                    return;
                } else {

                    CreateData(document.getElementById('account-name').value, Platform, userData)

                }

                }} sx={{ color: '#fff', backgroundColor: '#1e2124', width: '100px', height: '60px', justifyContent: 'center', alignItems: 'center', alignContent: 'center', textAlign: 'center', display: 'flex', fontSize: '20px', "&:hover": { cursor: 'pointer'} }}>
                Submit 
                </Box>                        
                </th>
                <th>
                <Box onClick={() => setState("ListAccounts")} sx={{ color: '#fff', backgroundColor: '#1e2124', width: '100px', height: '60px', justifyContent: 'center', alignItems: 'center', alignContent: 'center', textAlign: 'center', display: 'flex', fontSize: '20px', "&:hover": { cursor: 'pointer'} }}>
                Cancel 
                </Box>
                </th>
                </table>
                </Box>
                </Slide>
            </Stack>}
            {State === "AddAccountOptions" &&
            <Stack sx={{ width: { xs: '90vw', md: '50vw'}, height: '545px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            
            <Box sx={{ backgroundColor: '', borderRight: `5px solid ${Theme.BorderColor}`, borderLeft: `5px solid ${Theme.BorderColor}`, ":hover": { backgroundColor: 'darkblue', cursor: 'pointer'}}} onClick={() => {
                        setPlatform('Epic_Games')
                        setState("AddAccount")
            }}>
            <Stack direction="row" gap={1} sx={{  width: '90vw', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'clip'  }} >
            <img src={Epic_Games_Icon} style={{ height: '25px' }} /><Typography sx={{ fontFamily: 'Impact', fontSize: '25px', color: `${Theme.TextColor}`}}>EPIC GAMES</Typography>
            </Stack>
            </Box>
            <Box sx={{ backgroundColor: '', borderRight: `5px solid ${Theme.BorderColor}`, ":hover": { backgroundColor: 'darkblue', cursor: 'pointer'} }}  onClick={() => {
            setPlatform('Steam')
            setState("AddAccount")
            }}>
            <Stack direction="row" gap={1} sx={{  width: '90vw', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'clip'  }}>
            <FontAwesomeIcon icon={faSteam} style={{ color: `#fff`, fontSize: '30px'}} /> <Typography sx={{ fontFamily: 'Impact', fontSize: '25px', color: `${Theme.TextColor}`}}>STEAM</Typography>
            </Stack>
            </Box>
            <Box sx={{ backgroundColor: '', borderRight: `5px solid ${Theme.BorderColor}`, ":hover": { backgroundColor: 'darkblue', cursor: 'pointer'}}}  onClick={() => {
            setPlatform('PlayStation')
            setState("AddAccount")
            }}>
            <Stack direction="row" gap={1} sx={{  width: '90vw', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'clip' }}>
            <FontAwesomeIcon icon={faPlaystation} style={{ color: `#fff`, fontSize: '30px'}} /> <Typography sx={{ fontFamily: 'Impact', fontSize: '25px', color: `${Theme.TextColor}`}}>PLAYSTATION</Typography>
            </Stack>
            </Box>
            <Box sx={{ backgroundColor: '', borderRight: `5px solid ${Theme.BorderColor}`, ":hover": { backgroundColor: 'darkblue', cursor: 'pointer'}}}  onClick={() => {
            setPlatform('XBOX')
            setState("AddAccount")
            }}>
            <Stack direction="row" gap={1} sx={{  width: '90vw', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'clip'  }}>
            <FontAwesomeIcon icon={faXbox} style={{ color: `#fff`, fontSize: '30px'}} /> <Typography sx={{ fontFamily: 'Impact', fontSize: '25px', color: `${Theme.TextColor}`}}>XBOX</Typography>
            </Stack>
            </Box>

            </Stack>}
            {State === "ListAccounts" && <Stack sx={{ width: { xs: '90vw', md: '50vw'}, height: '510px', display: 'flex', alignItems: 'center', overflow: 'scroll'}} gap={1}>

            {fail === 'noaccounts' && <Slide in={fail === 'noaccounts'} direction="bottom">
                    <Alert severity="error" sx={{ width: '60%' }}>
                You need to add account to your account before signing up!
                </Alert>
            </Slide>}
           {Accounts.map((item, index) => (

            <Box sx={{ backgroundColor: '', width: '30vw', height: '50px', display: 'flex', justifyContent: 'center'}}>
            {item.Platform === "Epic_Games" && <Stack direction="row" gap={1}>
            <img src={Epic_Games_Icon} style={{ height: '50px', paddingLeft: '2px' }} />
           <Box sx={{ width: '30vw', backgroundColor: 'blue', height: '50px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}} onClick={() => clickOnAccount(item)}>
                <Typography sx={{ color: '#fff', fontFamily: 'Impact', fontSize: '30px'}}>{item.displayName.toUpperCase()}</Typography>
            </Box>
            <DeleteIcon sx={{ color: '#fff', fontSize: '50px', ":hover": { cursor: 'pointer', color: 'red' }}} onClick={() => DeleteData(item, userData)}/>
           </Stack>}
            {item.Platform === "Steam" && <Stack direction="row" gap={1}>
           <FontAwesomeIcon icon={faSteam} style={{ color: `#fff`, fontSize: '50px'}} />
           <Box sx={{ width: '30vw', backgroundColor: 'blue', height: '50px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}} onClick={() => clickOnAccount(item)}>
                <Typography sx={{ color: '#fff', fontFamily: 'Impact', fontSize: '30px'}}>{item.displayName.toUpperCase()}</Typography>
            </Box>
            <DeleteIcon sx={{ color: '#fff', fontSize: '50px', ":hover": { cursor: 'pointer', color: 'red' }}} onClick={() => DeleteData(item, userData)}/>
           </Stack>}
           {item.Platform === "PlayStation" && <Stack direction="row" gap={1}>
           <FontAwesomeIcon icon={faPlaystation} style={{ color: `#fff`, fontSize: '45px'}} />
           <Box sx={{ width: '30vw', backgroundColor: 'blue', height: '50px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}} onClick={() => clickOnAccount(item)}>
                <Typography sx={{ color: '#fff', fontFamily: 'Impact', fontSize: '30px'}}>{item.displayName.toUpperCase()}</Typography>
            </Box>
            <DeleteIcon sx={{ color: '#fff', fontSize: '50px', ":hover": { cursor: 'pointer', color: 'red' }}} onClick={() => DeleteData(item, userData)}/>
           </Stack>}
           {item.Platform === "XBOX" && <Stack direction="row" gap={1}>
           <FontAwesomeIcon icon={faXbox} style={{ color: `#fff`, fontSize: '50px'}} />
           <Box sx={{ width: '30vw', backgroundColor: 'blue', height: '50px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}} onClick={() => clickOnAccount(item)}>
                <Typography sx={{ color: '#fff', fontFamily: 'Impact', fontSize: '30px'}}>{item.displayName.toUpperCase()}</Typography>
            </Box>
            <DeleteIcon sx={{ color: '#fff', fontSize: '50px', ":hover": { cursor: 'pointer', color: 'red' }}} onClick={() => DeleteData(item, userData)}/>
           </Stack>}
            </Box>

           ))}


            </Stack>}
            
            
            </Box>
        </Stack>
    </Box>
  )
}

export default LinkedAccounts