import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';import { Stack, Box, Grid, TableContainer, TableHead, TableRow, TableCell, TableBody, CardContent, CardMedia, Typography } from '@mui/material';
import { Link, Navigate, useNavigate } from 'react-router-dom';
const Group = ({ Data, GroupId, TeamDetails, GroupMenuValue, GroupName }) => {

    const [Teams, setTeams] = useState([])

    useEffect(() => {

        if(!Data) return setTeams([])
        if(Data.length === 0) return;

        const Teams = Data.filter((a) => a.GroupStage.Group === GroupId);
        setTeams(Teams)

        console.log(GroupId, GroupMenuValue)

    }, [Data, GroupId, TeamDetails, GroupMenuValue])

  return (
    <Box sx={{minHeight: '70vh', width: '104vw', display: `${GroupId === GroupMenuValue ? 'flex' : 'none'}`, justifyContent: 'center'}}>
        <Box sx={{ height: '70vh', width: { xs: '104vw', md: '80vw'}, backgroundColor: '#00032b', borderRadius: '25px', display: 'flex', justifyContent: 'center', overflow: 'scroll', outline: '1px solid #030a98'}}>

                <Stack sx={{ textAlign: 'center'}}>
                <Typography sx={{ fontFamily: 'Impact', color: '#fff', fontSize: '50px', textDecoration: 'underline'}}>{GroupName}</Typography>

                <Stack>
                <Stack direction="row" gap={{ xs: 1, md: 15}}>
                    <Stack direction="row" sx={{ borderBottom: '2px solid #fff'}}>

                    <Box sx={{ height: '40px', width: { xs: '40px', md: '80px'}, backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>#</Typography>
                    </Box>

                    <Box sx={{ height: '40px', width: '80px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>LOGO</Typography>
                    </Box>  

                    <Box sx={{ height: '40px', width: '300px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex'}}}>
                        <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>NAME</Typography>
                    </Box>

                    </Stack>

                    <Stack direction="row" gap={{ xs: 1, md: 5}}>

                    <Stack direction="row" sx={{ borderBottom: '2px solid #fff' }} >

                    <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>SP</Typography>
                    </Box>

                    <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex'}}}>
                    <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>SW</Typography>
                    </Box>

                    <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex'}}}>
                        <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>SL</Typography>
                    </Box>

                    <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>SD</Typography>
                    </Box>

                    </Stack>

                    <Stack direction="row" sx={{ borderBottom: '2px solid #fff' }}>

                    <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>GP</Typography>
                    </Box>

                    <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex'}}}>
                    <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>GW</Typography>
                    </Box>

                    <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex'}}}>
                        <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>GL</Typography>
                    </Box>

                    <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>GD</Typography>
                    </Box>

                    </Stack>

                    <Stack direction="row" sx={{ borderBottom: '2px solid #fff'}}>

                    <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>PTS</Typography>
                    </Box>

                    </Stack>

                    </Stack>
                </Stack>
        <Stack gap={2}>
        {Teams.map((item, index) => (

        <Stack direction="row" gap={{ xs: 1, md: 15}}>
        <Stack direction="row">

        <Box sx={{ height: '40px', width: { xs: '40px', md: '80px'}, backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>{index + 1}</Typography>
        </Box>

        <Box sx={{ height: '40px', width: '80px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <img src={item.Logo} style={{ height: '40px', width: '40px'}}/>
        </Box>  

        <Box sx={{ height: '40px', width: '300px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex'}}}>
            <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>{item.Name.toUpperCase()}</Typography>
        </Box>

        </Stack>

        <Stack direction="row" gap={{ xs: 1, md: 5}}>

        <Stack direction="row" >

        <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>{item.GroupStage.Series.Won + item.GroupStage.Series.Loss}</Typography>
        </Box>

        <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex'}}}>
        <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>{item.GroupStage.Series.Won}</Typography>
        </Box>

        <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex'}}}>
            <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>{item.GroupStage.Series.Loss}</Typography>
        </Box>

        <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>{item.GroupStage.Series.Won - item.GroupStage.Series.Loss}</Typography>
        </Box>

        </Stack>

        <Stack direction="row" >

        <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>{item.GroupStage.Games.Won + item.GroupStage.Games.Loss}</Typography>
        </Box>

        <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex'}}}>
        <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>{item.GroupStage.Games.Won}</Typography>
        </Box>

        <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex'}}}>
            <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>{item.GroupStage.Games.Loss}</Typography>
        </Box>

        <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>{item.GroupStage.Games.Won - item.GroupStage.Games.Loss}</Typography>
        </Box>

        </Stack>

        <Stack direction="row">

        <Box sx={{ height: '40px', width: '40px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography sx={{ fontFamily: 'Impact', color: 'white', fontSize: '25px'}}>{item.GroupStage.Games.Won * 3}</Typography>
        </Box>

        </Stack>

        </Stack>
        </Stack>

        ))}
        </Stack>

        </Stack>
                </Stack>

        </Box>
    </Box>
  )
}

export default Group