import React, { useState, useEffect } from 'react';
import axios from 'axios';import { Stack, Box, Grid, TableContainer, TableHead, TableRow, TableCell, TableBody, CardContent, CardMedia, Typography } from '@mui/material';
import { Link, Navigate, useNavigate } from 'react-router-dom';

const Playoffs = ({ Data }) => {

    const [Rounds, setRounds] = useState([])

    useEffect(() => {

        console.log(Data)
        setRounds(Data.Rounds)

    }, [Data])

  return (
    <Box sx={{minHeight: '70vh', width: '104vw', display: 'flex', justifyContent: 'center'}}>

        {Rounds.length > 0 && 
        <Stack gap={5} direction="row">

        <Stack gap={2}>
            <Box sx={{ width: '300px', height: '80px', backgroundColor: '#030a98', borderRadius: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>{Rounds[0].Name}</Typography>

            </Box>

            <Stack gap={5}>
                <Stack sx={{ width: '300px', height: '100px', backgroundColor: '', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }} gap={2}>

                <Box sx={{ width: '300px', height: '50px', backgroundColor: '#00032b', outline: '1px solid #030a98', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '20px', color: '#fff'}}>{Rounds[0].Data[0].Team1}</Typography>
                </Box>
                <Box sx={{ height: '1px', width: '300px', backgroundColor: '#fff', display: 'flex'}}></Box>
                <Box sx={{ width: '300px', height: '50px', backgroundColor: '#2b2400', outline: '1px solid #987b03', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '20px', color: '#fff'}}>{Rounds[0].Data[0].Team2}</Typography>
                </Box>

                </Stack>

                <Stack sx={{ width: '300px', height: '100px', backgroundColor: '', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }} gap={2}>

                <Box sx={{ width: '300px', height: '50px', backgroundColor: '#00032b', outline: '1px solid #030a98', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '20px', color: '#fff'}}>{Rounds[0].Data[1].Team1}</Typography>
                </Box>
                <Box sx={{ height: '1px', width: '300px', backgroundColor: '#fff', display: 'flex'}}></Box>
                <Box sx={{ width: '300px', height: '50px', backgroundColor: '#2b2400', outline: '1px solid #987b03', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '20px', color: '#fff'}}>{Rounds[0].Data[1].Team2}</Typography>
                </Box>

                </Stack>

                <Stack sx={{ width: '300px', height: '100px', backgroundColor: '', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }} gap={2}>

                <Box sx={{ width: '300px', height: '50px', backgroundColor: '#00032b', outline: '1px solid #030a98', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '20px', color: '#fff'}}>{Rounds[0].Data[2].Team1}</Typography>
                </Box>
                <Box sx={{ height: '1px', width: '300px', backgroundColor: '#fff', display: 'flex'}}></Box>
                <Box sx={{ width: '300px', height: '50px', backgroundColor: '#2b2400', outline: '1px solid #987b03', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '20px', color: '#fff'}}>{Rounds[0].Data[2].Team2}</Typography>
                </Box>

                </Stack>

                <Stack sx={{ width: '300px', height: '100px', backgroundColor: '', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }} gap={2}>

                <Box sx={{ width: '300px', height: '50px', backgroundColor: '#00032b', outline: '1px solid #030a98', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '20px', color: '#fff'}}>{Rounds[0].Data[3].Team1}</Typography>
                </Box>
                <Box sx={{ height: '1px', width: '300px', backgroundColor: '#fff', display: 'flex'}}></Box>
                <Box sx={{ width: '300px', height: '50px', backgroundColor: '#2b2400', outline: '1px solid #987b03', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '20px', color: '#fff'}}>{Rounds[0].Data[3].Team2}</Typography>
                </Box>

                </Stack>

            </Stack>
        
        </Stack>

        <Stack gap={2}>
            <Box sx={{ width: '300px', height: '80px', backgroundColor: '#030a98', borderRadius: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>Semi Finals</Typography>

            </Box>

            <Stack gap={23} sx={{ paddingTop: '70px'}}>
                <Stack sx={{ width: '300px', height: '100px', backgroundColor: '', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }} gap={2}>

                <Box sx={{ width: '300px', height: '50px', backgroundColor: '#00032b', outline: '1px solid #030a98', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '20px', color: '#fff'}}>{Rounds[1].Data[0].Team1}</Typography>
                </Box>
                <Box sx={{ height: '1px', width: '300px', backgroundColor: '#fff', display: 'flex'}}></Box>
                <Box sx={{ width: '300px', height: '50px', backgroundColor: '#2b2400', outline: '1px solid #987b03', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '20px', color: '#fff'}}>{Rounds[1].Data[0].Team2}</Typography>
                </Box>

                </Stack>

                <Stack sx={{ width: '300px', height: '100px', backgroundColor: '', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }} gap={2}>

                <Box sx={{ width: '300px', height: '50px', backgroundColor: '#00032b', outline: '1px solid #030a98', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '20px', color: '#fff'}}>{Rounds[1].Data[1].Team1}</Typography>
                </Box>
                <Box sx={{ height: '1px', width: '300px', backgroundColor: '#fff', display: 'flex'}}></Box>
                <Box sx={{ width: '300px', height: '50px', backgroundColor: '#2b2400', outline: '1px solid #987b03', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '20px', color: '#fff'}}>{Rounds[1].Data[1].Team2}</Typography>
                </Box>

                </Stack>
            </Stack>
        
        </Stack>

        <Stack gap={2}>
            <Box sx={{ width: '300px', height: '80px', backgroundColor: '#030a98', borderRadius: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>Grand Finals</Typography>

            </Box>

            <Stack gap={23} sx={{ paddingTop: '210px'}}>

                <Stack sx={{ width: '300px', height: '100px', backgroundColor: '', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }} gap={2}>

                <Box sx={{ width: '300px', height: '50px', backgroundColor: '#00032b', outline: '1px solid #030a98', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '20px', color: '#fff'}}>{Rounds[2].Data[0].Team1}</Typography>
                </Box>
                <Box sx={{ height: '1px', width: '300px', backgroundColor: '#fff', display: 'flex'}}></Box>
                <Box sx={{ width: '300px', height: '50px', backgroundColor: '#2b2400', outline: '1px solid #987b03', borderRadius: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '20px', color: '#fff'}}>{Rounds[2].Data[0].Team2}</Typography>
                </Box>

                </Stack>

            </Stack>
        
        </Stack>

        </Stack>
        }
    </Box>
  )
}

export default Playoffs