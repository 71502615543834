import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Typography, Card, CardContent, CardMedia, Button, FormControl, InputLabel, TextField, Fade, Slide, Grow, Zoom, Popover, Box, Stack, BottomNavigation, BottomNavigationAction  } from '@mui/material';

import { LooksTwo, Looks4, Looks6 } from '@mui/icons-material'
import { BASE_URL } from '../utils/Constants';
import queryString from 'query-string';

// const Players = [
//     {
//         Name: 'PLAYER 1',
//         MMR: 1200,
//         Wins: 0,
//         Loss: 0
//     },
//     {
//         Name: 'PLAYER 2',
//         MMR: 1200,
//         Wins: 0,
//         Loss: 0
//     },
//     {
//         Name: 'PLAYER 3',
//         MMR: 1200,
//         Wins: 0,
//         Loss: 0
//     },
//     {
//         Name: 'PLAYER 4',
//         MMR: 1200,
//         Wins: 0,
//         Loss: 0
//     },
//     {
//         Name: 'PLAYER 5',
//         MMR: 1200,
//         Wins: 0,
//         Loss: 0
//     },
//     {
//         Name: 'PLAYER 6',
//         MMR: 1200,
//         Wins: 0,
//         Loss: 0
//     }
// ]

const RankedSystem = ({ userData }) => {

    const { Option } = queryString.parse(window.location.search);

    useEffect(() => {

        if(Option) {
            
            if(Option === "2Mans") {
                setOption(2)
                setColor("#00EAFC")
            } else if(Option === "4Mans") {
                setOption(4)
                setColor("#9B08F2")
            } else if(Option === "6Mans") {
                setOption(6)
                setColor("#F20818")
            }

        }

    }, [Option])

    const [Leaderboard, setOption] = useState(2)
    const [Value, setValue] = useState(0)
    const [Color, setColor] = useState("#00EAFC")

    const [Players, setPlayers] = useState([])
    const [TopPlayer, setTopPlayer] = useState({})

    useEffect(() => {

        async function getData() {

            const { data } = await axios.get(BASE_URL + '/rankedsystem/get');

            if(Leaderboard === 2) {
                setPlayers(data['2Mans'])
                if(data['2Mans'].length > 0) {

                    if(userData.userID) {

                        const h = await data['2Mans'].filter((a) => a.discordId === userData.profile.discordId)[0]
                        if(h.Wins) {
                            setTopPlayer(h)
                        } else {
                            setTopPlayer(data['2Mans'][0])
                        }

                    } else {
                        setTopPlayer(data['2Mans'][0])
                    }
                } else {
                    setTopPlayer({
                        Wins: 0,
                        Loss: 0,
                        MMR: 0
                    })
                }
            } else if(Leaderboard === 4) {
                setPlayers(data['4Mans'])
                if(data['4Mans'].length > 0) {
                    if(userData.userID) {

                        const h = await data['4Mans'].filter((a) => a.discordId === userData.profile.discordId)[0]
                        if(h.Wins) {
                            setTopPlayer(h)
                        } else {
                            setTopPlayer(data['4Mans'][0])
                        }

                    } else {
                        setTopPlayer(data['4Mans'][0])
                    }
                } else {
                    setTopPlayer({
                        Wins: 0,
                        Loss: 0,
                        MMR: 0
                    })
                }
            } else if(Leaderboard === 6) {
                setPlayers(data['6Mans'])
                if(data['6Mans'].length > 0) {
                    if(userData.userID) {

                        const h = await data['6Mans'].filter((a) => a.discordId === userData.profile.discordId)[0]
                        if(h.Wins) {
                            setTopPlayer(h)
                        } else {
                            setTopPlayer(data['6Mans'][0])
                        }

                    } else {
                        setTopPlayer(data['6Mans'][0])
                    }
                } else {
                    setTopPlayer({
                        Wins: 0,
                        Loss: 0,
                        MMR: 0
                    })
                }
            }

        }

        getData();

    }, [Leaderboard, userData])

  return (
    <Box sx={{ width: '100vw', height: { xs: '100vh', md: '150vh', xl: '100vh'}, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        
    <Stack sx={{ width: '100vw', height: '150vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} gap={2}>

    <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '8vw', color: Color}}>RANKED SYSTEM</Typography>


    <Stack direction={{ xs: 'column', md: "column", xl: 'row' }} gap={3}>
        
    <Stack gap={2} sx={{ display: { xs: 'none', md: 'flex'}}}>


    {userData.userID && <Box sx={{ backgroundColor: Color, width: '600px', height: '120px', borderRadius: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {TopPlayer.discordId === userData.profile.discordId && <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '50px'}}>YOUR STATS</Typography>}
        {TopPlayer.discordId != userData.profile.discordId && <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '50px'}}>TOP STATS</Typography>}
    </Box>}
    
    {!userData.userID && <Box sx={{ backgroundColor: Color, width: '600px', height: '120px', borderRadius: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '50px'}}>TOP STATS</Typography>
    </Box>}

    <Stack direction="row" gap={2}>

        <Stack sx={{ backgroundColor: '#292929', width: '290px', height: '224px', borderTopLeftRadius: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '70px', color: '#fff'}}>WINS</Typography>
            <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '50px', color: '#fff'}}>{TopPlayer.Wins}</Typography>

        </Stack>

        <Stack sx={{ backgroundColor: '#292929', width: '290px', height: '224px', borderTopRightRadius: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            
            <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '70px', color: '#fff'}}>LOSES</Typography>
            <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '50px', color: '#fff'}}>{TopPlayer.Loss}</Typography>
        
        </Stack>

    </Stack>

    <Stack direction="row" gap={2}>

    <Stack sx={{ backgroundColor: '#292929', width: '290px', height: '224px', borderBottomLeftRadius: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '70px', color: '#fff'}}>MMR</Typography>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '50px', color: '#fff'}}>{TopPlayer.MMR}</Typography>

    </Stack>

    <Stack sx={{ backgroundColor: '#292929', width: '290px', height: '224px', borderBottomRightRadius: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '70px', color: '#fff'}}>W/L %</Typography>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '50px', color: '#fff'}}>{Number(TopPlayer.Wins / (TopPlayer.Wins + TopPlayer .Loss) * 100).toFixed(1).replace('NaN', '00.0')}%</Typography>

    </Stack>

    </Stack>

    </Stack>


   <Stack>
    <Stack sx={{ backgroundColor: Color, width: { xs: '100vw', md: '800px'}, height: '100px', borderTopRightRadius: '25px', borderTopLeftRadius: '25px'}} direction="row">

    <Box sx={{ width: { xs: '60vw', md: '320px' }, height: '100px', backgroundColor: '', borderTopLeftRadius: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>PLAYER NAME</Typography>
    </Box>

    <Box sx={{ width: { xs: '40vw', md: '120px' }, height: '100px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>MMR</Typography>
    </Box>

    <Box sx={{ width: '120px', height: '100px', backgroundColor: '', display: { xs: 'none', md: 'flex'}, justifyContent: 'center', alignItems: 'center'}}>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>WINS</Typography>
    </Box>

    <Box sx={{ width: '120px', height: '100px', backgroundColor: '', display: { xs: 'none', md: 'flex'}, justifyContent: 'center', alignItems: 'center'}}>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>LOSES</Typography>
    </Box>

    <Box sx={{ width: '120px', height: '100px', backgroundColor: '', borderTopRightRadius: '25px', display: { xs: 'none', md: 'flex'}, justifyContent: 'center', alignItems: 'center'}}>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>W/L %</Typography>
    </Box>
    
    </Stack>
    <Box sx={{ height: '450px', backgroundColor: '', overflow: 'scroll', paddingBottom: '50px', borderBottomRightRadius: '25px', borderBottomLeftRadius: '25px' }}>
    {Players.map((item, index) => (

    <Stack sx={{ backgroundColor: `${index & 1 !=0 ? '#292929' : '#383838'}`, width: { xs: '100vw', md: '800px'}, minHeight: '100px', display: `${item.Wins + item.Loss != 0 ? 'flex' : 'none'}` }} direction="row">

    <Box sx={{ width: { xs: '60vw', md: '320px' }, height: '100px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: `1px solid ${Color}`, overflow: 'scroll'}}>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px', color: '#fff'}}>{item.Username}</Typography>
    </Box>

    <Box sx={{ width: { xs: '40vw', md: '120px' }, height: '100px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: `1px solid ${Color}`}}>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px', color: '#fff'}}>{item.MMR}</Typography>
    </Box>

    <Box sx={{ width: '120px', height: '100px', backgroundColor: '', display: { xs: 'none', md: 'flex'}, justifyContent: 'center', alignItems: 'center', borderRight: `1px solid ${Color}`}}>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px', color: '#fff'}}>{item.Wins}</Typography>
    </Box>

    <Box sx={{ width: '120px', height: '100px', backgroundColor: '', display: { xs: 'none', md: 'flex'}, justifyContent: 'center', alignItems: 'center', borderRight: `1px solid ${Color}`}}>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px', color: '#fff'}}>{item.Loss}</Typography>
    </Box>

    <Box sx={{ width: '120px', height: '100px', backgroundColor: '', display: { xs: 'none', md: 'flex'}, justifyContent: 'center', alignItems: 'center'}}>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px', color: '#fff'}}>{Number(item.Wins / (item.Wins + item.Loss) * 100).toFixed(1).replace('NaN', '00.0')}%</Typography>
    </Box>
    
            
    </Stack>

    ))}
    </Box>

   
   </Stack>

   <Stack sx={{ width: '150px', height: '150px', display: { xs: 'none', md: 'none', xl: 'flex'}}}>

    <Box sx={{ height: '50px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: `${Leaderboard === 2 ? 'black' : Color}`, backgroundColor: `${Leaderboard === 2 ? Color : '#383838'}`, ":hover": { cursor: 'pointer' } }} onClick={() => {
        setOption(2)
        setColor("#00EAFC")
    }}>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>2 MANS</Typography>
    </Box>

    <Box sx={{ height: '50px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: `${Leaderboard === 4 ? 'black' : Color}`, backgroundColor: `${Leaderboard === 4 ? Color : '#383838'}`, ":hover": { cursor: 'pointer' } }} onClick={() => {
        setOption(4)
        setColor("#9B08F2")
    }}>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>4 MANS</Typography>
    </Box>

    <Box sx={{ height: '50px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: `${Leaderboard === 6 ? 'black' : Color}`, backgroundColor: `${Leaderboard === 6 ? Color : '#383838'}`, ":hover": { cursor: 'pointer' }  }} onClick={() => {
        setOption(6)
        setColor("#F20818")
    }}>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>6 MANS</Typography>
    </Box>

   </Stack>

    </Stack>

    </Stack>

    <Box sx={{ position: 'fixed', bottom: 0, width: '100vw', display: { xs: 'unset', md: 'unset', xl: 'none'} }}>
       <BottomNavigation
        showLabels
        value={Value}
        sx={{backgroundColor: '#11101d'}}
        onChange={(event, newValue) => {
            setValue(newValue)

            console.log(newValue)

            if(newValue === 0) {

                setOption(2)
                setColor("#00EAFC")

            } else if(newValue === 1) {

                setOption(4)
                setColor("#9B08F2")
                
            } else if(newValue === 2) {

                setOption(6)
                setColor("#F20818")
                
            }

        }}
        >
        <BottomNavigationAction label="2Mans" icon={<LooksTwo />} sx={{ color: '#fff' }}/>
        <BottomNavigationAction label="4Mans" icon={<Looks4 />} sx={{ color: '#fff' }}/>
        <BottomNavigationAction label="6Mans" icon={<Looks6 />} sx={{ color: '#fff' }}/>
        </BottomNavigation>
       </Box>
    
   </Box>
  )
}

export default RankedSystem