import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, CardMedia, Button, FormControl, InputLabel, TextField, Fade, Slide, Grow, Zoom, Popover, Box, Stack, BottomNavigation, BottomNavigationAction, Backdrop, CircularProgress  } from '@mui/material';
import { Group, Playoffs, Teams } from '../components/Tourney';
import axios from 'axios';
import { Person, Groups, Restore, Favorite, LocationOn, LooksOne, LooksTwo, Leaderboard, GroupWork, Mediation } from '@mui/icons-material'
import PlayerSignup from '../components/Tourney/PlayerSignup';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../utils/Constants';

const Tourney = ({ userData }) => {

    const { id } = useParams();

    const [State, setState] = useState('Loading')

    const [TourneyData, setTourneyData] = useState({})

    const [TotalPlayers, setTotalPlayers] = useState(0)
    const [TotalTeams, setTotalTeams] = useState(0)

    async function setPlayerStatus(h, data) {

        const Status = await axios.post(BASE_URL + '/tourney/player/get', h)
       
        const Application = Status.data.Applications;
        const IsPlayer = Status.data.IsPlayer;

        if(!data.Config) return;

        if(data.Config.Player_Joining === false) return;

        if(h.userData.userID) {
            setRender_JoinPlayer(true)
        }

        if(Application.length > 0) {
            setRender_WaitingAcceptance(true)
            setRender_JoinPlayer(false)
        }
        if(IsPlayer.length > 0) {
                   
        if(TourneyData.Config.Team_Joining === true) {
            setRender_JoinTeam(true)
        } else {
            setRender_YouAreSignedUp(true)
        }
            setRender_JoinPlayer(false)
                    
        }

    }

    useEffect(() => {

        async function getTourney() {

            const { data } = await axios.get(`https://api.brooksiesgaming.com/tourney/${id}`);

            if(!data) return;

            setTourneyData(data)
            setState("MainPage")
            setTotalTeams(data.Teams.length)
            setTotalPlayers(data.Players.length)

            const h = {
                TourneyData: data, 
                userData
            }

            setPlayerStatus(h, data)

        }

        getTourney()

    }, [id, userData])

    useEffect(() => {

        const h = {
            TourneyData: TourneyData, 
            userData
        }

        setPlayerStatus(h, TourneyData)

    })
    
    
    const [BottomNavigationValue, setBottomNavigationValue] = useState(0)

    const [Render_JoinPlayer, setRender_JoinPlayer] = useState(false)
    const [Render_JoinTeam, setRender_JoinTeam] = useState(false)
    const [Render_WaitingAcceptance, setRender_WaitingAcceptance] = useState(false)
    const [Render_YouAreSignedUp, setRender_YouAreSignedUp] = useState(false)

    const [Render_PlayerSignup, setRender_PlayerSignup] = useState(false)

    const [GroupValue, setGroupValue] = useState(0)

    async function Join_Player() {

        setRender_PlayerSignup(true)

    }
 
  return (
    <Box>
    {State === "Loading" && 
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={State === "Loading"}
    >
    <CircularProgress color="inherit" />
    </Backdrop>}
    {State === "MainPage" && 
    <Box>
        {Render_PlayerSignup === true && <PlayerSignup userData={userData} TourneyData={TourneyData}/>}
        {Render_PlayerSignup === false && <Box>
        <Box sx={{ display: 'flex', width: '100vw', height: '150px', padding: '0', border: '0', verticalAlign: 'middle', backgroundColor: 'Black', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${TourneyData.Banner})`}}></Box>
        <Box sx={{ backgroundColor: '#00032b', width: '100vw', height: '150px'}}>
        <Stack direction="row" sx={{ paddingLeft: { xs: '20px', md: '100px'}}}>
        <Box sx={{ paddingTop: '15px', width: '60vw', display: 'flex', justifyContent: 'center'}}>
        <Stack>
        <Typography sx={{ fontFamily: 'Boomer Tantrum', color: '#f1b703', fontSize: { xs: '3vw', md: '2vw'}}}>{TourneyData.Name}</Typography>
        <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', paddingTop: '10px   ' }}>
        <Person sx={{ color: '#fff', fontSize: '30px' }}/>
        <Typography sx={{ color: '#fff', fontFamily: 'Impact', fontSize: '20px', paddingLeft: '10px'}}>{TotalPlayers}</Typography>
        <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', paddingLeft: '50px' }}>
        <Groups sx={{ color: '#fff', fontSize: '35px'}}/>
        <Typography sx={{ color: '#fff', fontFamily: 'Impact', fontSize: '20px', paddingLeft: '10px'}}>{TotalTeams}</Typography>
        </Stack>
        {Render_JoinPlayer === true && <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', paddingLeft: '50px', display: { xs: 'none', md: 'unset'} }} onClick={() => Join_Player()}>
        <Button variant="contained" sx={{ color: 'black', fontSize: '15px', fontFamily: 'Impact', width: '100px', backgroundColor: '#f1b703', ":hover": { backgroundColor: '#f1b703'}}}>Join</Button>
        </Stack>}
        {Render_JoinTeam === true && <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', paddingLeft: '50px', display: { xs: 'none', md: 'unset'} }}>
        <Button variant="contained" sx={{ color: 'black', fontSize: '15px', fontFamily: 'Impact', width: '200px', backgroundColor: '#f1b703', ":hover": { backgroundColor: '#f1b703'}}}>Join / Create Team</Button>
        </Stack>}
        {Render_WaitingAcceptance === true && <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', paddingLeft: '50px', display: { xs: 'none', md: 'unset'} }}>
        <Button variant="contained" sx={{ color: 'black', fontSize: '15px', fontFamily: 'Impact', width: '200px', backgroundColor: '#f1b703', ":hover": { backgroundColor: '#f1b703'}}}>Waiting To Be Accepted...</Button>
        </Stack>}

        {Render_YouAreSignedUp === true && <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', paddingLeft: '50px', display: { xs: 'none', md: 'unset'} }}>
        <Button variant="contained" sx={{ color: 'black', fontSize: '15px', fontFamily: 'Impact', width: '200px', backgroundColor: '#f1b703', ":hover": { backgroundColor: '#f1b703'}}}>You Are Signed Up!</Button>
        </Stack>}

        </Stack>
        {Render_JoinPlayer === true && <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', paddingLeft: '50px', display: { xs: 'unset', md: 'none'}, paddingTop: '25px' }} onClick={() => Join_Player()}>
        <Button variant="contained" sx={{ color: 'black', fontSize: '15px', fontFamily: 'Impact', width: '100px', backgroundColor: '#f1b703', ":hover": { backgroundColor: '#f1b703'}}}>Join</Button>
        </Stack>}
        {Render_JoinTeam === true && <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', paddingLeft: '50px', display: { xs: 'unset', md: 'none'}, paddingTop: '25px', paddingLeft: '30px' }}>
        <Button variant="contained" sx={{ color: 'black', fontSize: '15px', fontFamily: 'Impact', width: '200px', backgroundColor: '#f1b703', ":hover": { backgroundColor: '#f1b703'}}}>Join / Create Team</Button>
        </Stack>}
        {Render_WaitingAcceptance === true && <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', paddingLeft: '50px', display: { xs: 'unset', md: 'none'}, paddingTop: '25px', paddingLeft: '30px' }}>
        <Button variant="contained" sx={{ color: 'black', fontSize: '15px', fontFamily: 'Impact', width: '200px', backgroundColor: '#f1b703', ":hover": { backgroundColor: '#f1b703'}}}>Waiting To Be Accepted...</Button>
        </Stack>}

        {Render_YouAreSignedUp === true && <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', paddingLeft: '50px', display: { xs: 'unset', md: 'none'}, paddingTop: '25px', paddingLeft: '30px' }}>
        <Button variant="contained" sx={{ color: 'black', fontSize: '15px', fontFamily: 'Impact', width: '200px', backgroundColor: '#f1b703', ":hover": { backgroundColor: '#f1b703'}}}>You Are Signed Up</Button>
        </Stack>}
        </Stack>
        </Box>
        <Box sx={{ paddingRight: { xs: '0px', md: '100px'}, width: '40vw', height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        
        {/* <Box sx={{ height: '50px', width: '150px', border: '1px solid #f1b703', display: 'flex', color: '#f1b703', alignItems: 'center', justifyContent: 'center', ":hover": { backgroundColor: '#f1b703', color: 'black', cursor: 'pointer'}}}>
        <Typography sx={{ fontFamily: 'Impact', fontSize: '30px'}}>JOIN</Typography>
        </Box> */}

        <Box sx={{ height: '150px', width: '150px', border: '1px solid #f1b703', display: 'flex', borderRadius: '25px', justifyContent: 'center', alignItems: 'center'}}>

            <img src={TourneyData.Logo} style={{ width: '90%', height: '90%'}}/>
        
        </Box>

        </Box>
        </Stack>
        

        <Box sx={{ backgroundColor: '', minHeight: '70vh', width: '104vw', display: 'flex', justifyContent: 'center', paddingTop: '30px', overflow: 'scroll', paddingBottom: '100px'}}>
    
            {TourneyData.Config.BottomBar.map((item, index) => (

                <Box>

                {BottomNavigationValue === index && <Box sx={{ width: { xs: '90vw', md: '90vw'}, minHeight: '80vh', display: 'flex', justifyContent: 'center', backgroundColor: ''}}>

                {item.Type === "Teams" && <Teams Teams={TourneyData.Teams}/>}
                {item.Type === "Playoffs" && <Playoffs Data={TourneyData.Config.Playoffs}/>}
                
                </Box>}

                </Box>
    
            ))}

            {BottomNavigationValue >= TourneyData.Config.BottomBar.length && <Box sx={{ width: { xs: '90vw', md: '90vw'}, minHeight: '80vh', display: 'flex', justifyContent: 'center', backgroundColor: ''}}>

            {TourneyData.Config.Groups.map((item, index) => (

            <Group Data={TourneyData.Teams} GroupId={item.ID} TeamDetails={TourneyData.Config.TeamDetails} GroupMenuValue={GroupValue} GroupName={item.Name}/>

            ))}

            </Box>}


        </Box>

        </Box>
        </Box>}
       <Box sx={{ position: 'fixed', bottom: 0, width: '100vw' }}>
       <BottomNavigation
        showLabels
        value={BottomNavigationValue}
        sx={{backgroundColor: '#11101d'}}
        onChange={(event, newValue) => {
            setBottomNavigationValue(newValue)
        }}
        >
        {TourneyData.Config.BottomBar.map((item, index) => (
            <BottomNavigationAction label={item.Name} sx={{ color: '#fff' }} icon={<Box>
                {item.Icon === "Groups" && <Groups />}
                {item.Icon === "Mediation" && <Mediation />}
            </Box>} />
        ))}
        
        {TourneyData.Config.Groups.map((item, index) => (
            <BottomNavigationAction label={item.Name} icon={<GroupWork />} sx={{ color: '#fff' }} onClick={() => setGroupValue(item.ID)}/>
        ))}
        </BottomNavigation>
       </Box>
    </Box>}
    </Box>
  )
}

export default Tourney