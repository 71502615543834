import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, CardMedia, Button, FormControl, InputLabel, TextField, Fade, Slide, Grow, Zoom, Popover, Box, Stack, Backdrop, CircularProgress  } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const Invite = ({ userData }) => {

    const [Data, setData] = useState({
      Team: {
        ID: 'TEAMID',
        Name: 'CLINICAL MASTERMINDS',
        Logo: 'https://imgur.com/kigEufG.png'
      },
      Role: {
        Main: 'MAIN',
        Additional: 'null'
      },
      Information: "Excited him now natural saw passage offices you minuter. At by asked being court hopes. Farther so friends am to detract. Forbade concern do private be. Offending residence but men engrossed shy. Pretend am earnest offered arrived company so on. Felicity informed yet had admitted strictly how you. In alteration insipidity impression by travelling reasonable up motionless. Of regard warmth by unable sudden garden ladies. No kept hung am size spot no. Likewise led and dissuade rejoiced welcomed husbands boy. Do listening on he suspected resembled. Water would still if to. Position boy required law moderate was may."
    })

    const { id } = useParams();

    useEffect(() => {

        console.log(id)

    }, [id])

    async function Accept() {

    console.log(Data.Team.ID)
    console.log(userData.userID)

    }

    async function Decline() {

    console.log(Data.Team.ID)
    console.log(userData.userID)

    }

  return (
    <Box sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#11101d'}}>
      
      <Stack direction={{ xs: 'column', xl: 'row'}} sx={{ paddingLeft: { xs: '0', md: '80px' }, display: 'flex', alignItems: 'center', justifyContent: 'center'}} gap={{ xs: 2, xl: 5}}>

        <Stack>
        <Box sx={{ width: { xs: '100vw', md: '900px'}, height: '500px', backgroundColor: '#292929', borderTopRightRadius: '25px', borderTopLeftRadius: '25px', display: 'flex', justifyContent: 'center', paddingTop: '20px', textAlign: 'center' }}>
          <Stack>
            <Stack>
              <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: { xs: '30px', md: '50px', md: '60px'} }}>YOU HAVE BEEN</Typography>
              <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: { xs: '30px', md: '50px', md: '60px'} }}>INVITED TO JOIN:</Typography>

              <Stack direction="row" sx={{ paddingTop: '10px', paddingRight: '10px', height: '150px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Box sx={{ display: { xs: 'none', md: 'flex'}}}><img src={Data.Team.Logo} style={{ width: '150px', height: '150px'}} /></Box>
                <Box sx={{ minHeight: '80px', backgroundColor: '#171717', borderRadius: '5px'}}>
                <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: { xs: '40px', md: '50px'}, backgroundColor: '', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{Data.Team.Name}</Typography>
                </Box>
              </Stack>
             <Stack gap={3}>
              <Stack direction="row" sx={{ paddingTop: '10px', paddingRight: '10px', height: { xs: '50px', md: '30px'}, backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}} gap={2}>
                  <Box sx={{ minHeight: '50px', backgroundColor: ''}}>
                  <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: { xs: '30px', md: '40px'}, backgroundColor: '', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>POSITION:</Typography>
                  </Box>
                  <Box sx={{ minHeight: '50px', backgroundColor: '#171717', borderRadius: '5px'}}>
                  <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: { xs: '30px', md: '40px'}, backgroundColor: '', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{Data.Role.Main}</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" sx={{ paddingTop: '20px', paddingRight: '10px', height: '50px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}} gap={2}>
                  <Box sx={{ minHeight: '50px', backgroundColor: ''}}>
                  <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: { xs: '30px', md: '30px', md: '40px'}, backgroundColor: '', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>ADDITIONAL:</Typography>
                  </Box>
                  <Box sx={{ minHeight: '50px', backgroundColor: '#171717', borderRadius: '5px'}}>
                  <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: { xs: '30px', md: '40px'}, backgroundColor: '', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{`${`${Data.Role.Additional}`}`.replace(null, 'None')}</Typography>
                  </Box>
                </Stack>
             </Stack>
            </Stack>
          </Stack>
        </Box>
        <Stack sx={{ width: { xs: '100vw', md: '900px'}, height: '200px', backgroundColor: '#1a1a1a', borderBottomRightRadius: '25px', borderBottomLeftRadius: '25px', display: { xs: 'none', md: 'flex'}, alignItems: 'center', paddingTop: '10px' }}>

            <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: { xs: '20px', md: '30px'}, textDecoration: 'underline' }}>ADDITIONAL INFORMATION</Typography>

            <Box sx={{ width: { xs: '100vw', md: '900px'}, height: '150px', backgroundColor: '', display: 'flex', justifyContent: 'center', textAlign: 'center', overflow: 'scroll'}}> 

              <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: '20px', overflow: 'scroll', paddingTop: '' }}>{Data.Information}</Typography>

            </Box>

        </Stack>
        </Stack>

      <Stack gap={5} direction={{ xs: 'row', xl: 'column'}} sx={{ height: { xs: '200px', xs: ''}, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      
        <Box sx={{ width: { xs: '40vw', xl: '300px'}, height: '150px', backgroundColor: 'green', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: { xs: '', xl: '41px'}, ":hover": { cursor: 'pointer' }}} onClick={() => Accept()}>
          <Typography sx={{ color: '#292929', fontFamily: 'Boomer Tantrum', fontSize: { xs: '30px', md: '40px', md: '65px'} }}>ACCEPT</Typography>
        </Box>
        <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: '20px', width: '320px', textAlign: 'center', display: { xs: 'none', xl: 'unset'} }}>◉ NOTE THAT ACCEPTING THIS WILL AUTOMATICALLY CANCEL ALL YOUR OTHER TEAM INVITES</Typography>

        <Box sx={{ width: { xs: '40vw', xl: '300px'}, height: '150px', backgroundColor: 'red', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: { xs: '', xl: '41px'}, ":hover": { cursor: 'pointer' }}} onClick={() => Decline()}>
          <Typography sx={{ color: '#292929', fontFamily: 'Boomer Tantrum', fontSize: { xs: '30px', md: '40px', md: '65px'} }}>DECLINE</Typography>
        </Box>
        <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: '20px', width: '310px', textAlign: 'center', display: { xs: 'none', xl: 'unset'} }}>◉ DECLINING THIS TEAM INVITATION WILL NOT AFFECT ANY OF YOUR OTHER TEAM INVITATIONS</Typography>

      </Stack>

      </Stack>

    </Box>
  )
}

export default Invite