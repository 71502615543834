import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios'
import { Account, HomePage, Invite, Profile, RankedSystem, Team, TeamsDashboard, Tourney } from './pages';
import { authorizeUser, getUser } from './utils/supabase';
import { AdminNav, BottomBar, NavBar } from './components/General';
import { BASE_URL } from './utils/Constants';
import { Dashboard, Game_Report, League_Config, Manage_Tournaments, Players, Tournament_Dashboard, Tournament_Edit } from './pages/Staff';
import { Playoffs, Teams } from './components/Tourney'
import { Groups, Mediation } from '@mui/icons-material'

const App = () => {

    const [userData, setuserData] = useState({})
    const [LoggedIn, setLoggedIn] = useState(false)
    const [Tourneys, setTourneys] = useState([])

    const [PermLevel, setPermLevel] = useState("")

    const AuthUserWrapper = ({ children }) => {

        console.log(LoggedIn)

        if(LoggedIn === false) {
            authorizeUser();
        }
    };

    useEffect(() => {

        async function getPermLevel(Perms) {
    
            const Moderation = await Perms.filter((a) => a === 'MODERATOR');
            const Admin = await Perms.filter((a) => a === 'ADMIN');
            const Developer = await Perms.filter((a) => a === 'DEVELOPER');
            const Founder = await Perms.filter((a) => a === 'FOUNDER');
    
            var Level = ''
    
            if(Moderation.length > 0) {
                Level = 'MODERATOR'
            }
            if(Admin.length > 0) {
                Level = 'ADMIN'
            }
            if(Developer.length > 0) {
                Level = 'DEVELOPER'
            }
            if(Founder.length > 0) {
                Level = 'FOUNDER'
            }
    
            console.log('Final Perm Level: ' + Level)
            setPermLevel(Level)
            
    
        }

        async function getUserData() {
            const h = await getUser();    
            const { data } = await axios.post(BASE_URL + '/webusers/fetch', h)
            setuserData(data)
            if(data.userID) {
                setLoggedIn(true)
                getPermLevel(data.info.Perms)
            }
        }

        async function getTourneys() {

            const { data } = await axios.get(BASE_URL + '/tourney/all');
            setTourneys(data)

        }
        
        getTourneys()
        getUserData()   

    }, [])

   return (
    <BrowserRouter>
    
    <Box sx={{ }}>
    <AdminNav userData={userData} PermLevel={PermLevel}/>
    <NavBar userData={userData} Tourneys={Tourneys} />
        <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/profile/:id" exact element={<Profile />} />
            <Route path="/RankedSystem" exact element={<RankedSystem userData={userData} />} />
            
            <Route path="/tourney/:id/dashboard" exact element={<Tourney userData={userData} />} />
            <Route path="/invite/:id" exact element={<Invite userData={userData} />} />
            
            <Route path="/team/dashboard" exact element={<TeamsDashboard userData={userData}/>} />
            <Route path="/team/:id" exact element={<Team />} />
        </Routes>
        
        {LoggedIn === true &&
        <Routes>
            <Route path="/account" exact element={<Account userData={userData} />} />
        </Routes>}

        {LoggedIn === false &&
        <Routes>
            <Route path="/auth" exact element={<AuthUserWrapper></AuthUserWrapper>} />
        </Routes>}
        

        {/* Perm Routes */}

        <Routes>
            <Route path="/Staff/dashboard" exact element={<Dashboard PermLevel={PermLevel}/>} />
            <Route path="/Staff/players" exact element={<Players PermLevel={PermLevel}/>} />
            <Route path="/Staff/teams" exact element={<Teams PermLevel={PermLevel}/>} />
            <Route path="/Staff/game_report" exact element={<Game_Report PermLevel={PermLevel}/>} />
            <Route path="/Staff/Manage_Tournaments/dashboard" exact element={<Tournament_Dashboard PermLevel={PermLevel}/>} />
            <Route path="/tourney/:id/edit" exact element={<Tournament_Edit PermLevel={PermLevel}/>} />
        </Routes>

    </Box>

    </BrowserRouter>
   )
}

export default App

