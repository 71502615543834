import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Stack, Box, Typography } from '@mui/material';

const Team = () => {

    const [TeamData, setTeamData] = useState({
        Name: 'TEAM NAME',
        Logo: 'https://i.imgur.com/Ihbag0e.png'
    })

    const [Players, setPlayers] = useState([
        {
            Name: 'Player 1',
            Avatar: ''
        },
        {
            Name: 'Player 1',
            Avatar: ''
        },
        {
            Name: 'Player 1',
            Avatar: ''
        }
    ])

  return (
    <Box sx={{minHeight: '70vh', width: '104vw', display: 'flex', justifyContent: 'center'}}>

    <Stack gap={5}>

    <Box sx={{ display: 'flex', width: '104vw', height: '300px', padding: '0', border: '0', verticalAlign: 'middle', backgroundColor: '#00032b', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url("")`, opacity: '1', borderBottom: '2px solid blue'}}>
        
        <Stack direction="row">
            <Box sx={{ width: '50vw', height: '300px', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Stack>
                    <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: { xs: '25px', md: '50px'}, color: '#fff', textDecoration: 'underline'}}>{TeamData.Name}</Typography>
                    <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '20px', color: '#fff', display: { xs: 'none', md: 'unset'}}}>TEAM DESCRIPTION</Typography>
                </Stack>
            </Box>
            <Box sx={{ width: '50vw', height: '300px', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <Box sx={{ width: { xs: '20vw', md: '250px'}, height: { xs: '20vw', md: '250px'} }}>
                <img src={TeamData.Logo} style={{ width: '100%', height: '100%'}}/>
                </Box>

            </Box>
        </Stack>
        
    </Box>

    <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '50px', color: '#fff', textDecoration: 'underline', paddingLeft: { xs: '60px', md: '200px'}}}>PLAYERS</Typography>

    <Stack sx={{ width: '104vw', minHeight: '60vh', backgroundColor: '', display: 'flex', justifyContent: 'center'}} direction={'row'} flexWrap='wrap' gap={{ xs: 1, md: 5}}>

    {Players.map((player, index) => (

        <Stack sx={{ width: '350px', height: '450px', outline: '1px solid blue', backgroundColor: '#00032b', opacity: '0.7', ":hover": { opacity: 1, cursor: 'pointer'}, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Box sx={{ width: '350px', height: '350px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url("https://i.imgur.com/Ihbag0e.png")`}}>
                
            </Box>
            <Box sx={{ width: '350px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'scroll'}}>
                <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '30px', color: '#fff' }}>PLAYER NAME</Typography>
            </Box>
        </Stack>

    ))}

    </Stack>

    </Stack>
    </Box>
  )
}

export default Team