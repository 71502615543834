import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';import { Stack, Box, Grid, TableContainer, TableHead, TableRow, TableCell, TableBody, CardContent, CardMedia, Typography } from '@mui/material';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { CheckCircle } from '@mui/icons-material';


const Teams = ({ Teams, BackgroundColor, TeamDetails }) => {

  const Navigate = useNavigate();

  const [BackgroundCOLOR, setBackgroundCOLOR] = useState("#00032b")
  const [ShowTeamDetails, setShowTeamDetails] = useState(false)

  useEffect(() => {

    if(BackgroundColor) {

      setBackgroundCOLOR(BackgroundColor)

    }

    if(TeamDetails === false) {
      setShowTeamDetails(TeamDetails)
    } else if(TeamDetails === true) {
      setShowTeamDetails(TeamDetails)
    }

  }, [BackgroundColor, TeamDetails])

  return (
   <Box>
    {Teams && <Box>{Teams.length > 0 && <Stack direction={'row'} flexWrap='wrap' justifyContent="start" gap={10} backgroundColor="" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {Teams.map((item, idx) => (

            <Box sx={{ height: '350px', width: '250px', backgroundColor: BackgroundCOLOR, borderRadius: '10px', border: '1px solid #030a98', display: 'flex', justifyContent: 'center'}}>
                <Stack sx={{ display: 'flex', alignItems: 'center'}} gap={2}>
                    <img src={item.Logo} style={{ width: '250px', height: '250px'}}/>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography sx={{ fontSize: '19px', color: '#fff', fontFamily: 'Boomer Tantrum'}}>{item.Name}</Typography>
                    </Box>
                    {ShowTeamDetails === true && <Box sx={{ height: '30px', width: '220px', border: '1px solid #fff', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', ":hover": { cursor: 'pointer', backgroundColor: '#00021b'} }} onClick={() => Navigate(`/team/${item.ID}`)}>
                      <Typography sx={{ fontSize: '15px', color: '#fff', fontFamily: 'Impact'}}>Team Details</Typography>
                    </Box>}
                </Stack>
            </Box>
 
          ))}
    </Stack>}</Box>}
   </Box>
  )
}

export default Teams