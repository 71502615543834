import React, { useEffect, useState } from 'react'
import { Box, Backdrop, SpeedDial, SpeedDialIcon, SpeedDialAction, Link } from '@mui/material';
import { FileCopyOutlined, Save, Print, Share, Person, People, Scoreboard, Construction, Dashboard } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom';

const IMAGE = 'https://i.imgur.com/lyzyxnG.png'

const AdminNav = ({ userData, PermLevel }) => {

    const navigate = useNavigate();

    async function Redirect(data) {
        navigate(data.location)
    }

    const Moderator_Actions = [
        { icon: <Dashboard onClick={() => Redirect({ location: '/Staff/dashboard' })}/>, name: 'Dashboard' },
        { icon: <Person onClick={() => Redirect({ location: '/Staff/players' })}/>, name: 'Players' },
        { icon: <People onClick={() => Redirect({ location: '/Staff/teams' })}/>, name: 'Teams' }
    ];

    const Admin_Actions = [
        { icon: <Dashboard onClick={() => Redirect({ location: '/Staff/dashboard' })}/>, name: 'Dashboard' },
        { icon: <Person onClick={() => Redirect({ location: '/Staff/players' })}/>, name: 'Players' },
        { icon: <People onClick={() => Redirect({ location: '/Staff/teams' })}/>, name: 'Teams' },
        { icon: <Scoreboard onClick={() => Redirect({ location: '/Staff/game_report' })}/>, name: 'Game Report' }
    ];

    const Founder_Actions = [
        { icon: <Dashboard onClick={() => Redirect({ location: '/Staff/dashboard' })}/>, name: 'Dashboard' },
        { icon: <Person onClick={() => Redirect({ location: '/Staff/players' })}/>, name: 'Players' },
        { icon: <People onClick={() => Redirect({ location: '/Staff/teams' })}/>, name: 'Teams' },
        { icon: <Scoreboard onClick={() => Redirect({ location: '/Staff/game_report' })}/>, name: 'Game Report' },
        { icon: <Construction onClick={() => Redirect({ location: '/Staff/Manage_Tournaments/dashboard' })}/>, name: 'Tournaments' }
    ];


    const [actions, setactions] = useState([])

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


  return (
    <Box sx={{ display: { xs: 'none', md: 'unset'}}}>
      <Backdrop open={open} />
      {PermLevel === 'MODERATOR' &&  <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<img src={IMAGE} style={{ height: '50px'}} onClick={() => Redirect({ location: '/' })}/>}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {Moderator_Actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={handleClose}
          />
        ))}
      </SpeedDial>}

      {PermLevel === 'ADMIN' &&  <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<img src={IMAGE} style={{ height: '50px'}} onClick={() => Redirect({ location: '/' })}/>}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {Admin_Actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={handleClose}
          />
        ))}
      </SpeedDial>}

      {PermLevel === 'DEVELOPER' &&  <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<img src={IMAGE} style={{ height: '50px' }} onClick={() => Redirect({ location: '/' })}/>}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {Founder_Actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={handleClose}
          />
        ))}
      </SpeedDial>}

      {PermLevel === 'FOUNDER' &&  <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<img src={IMAGE} style={{ height: '50px' }} onClick={() => Redirect({ location: '/' })}/>}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {Founder_Actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={handleClose}
          />
        ))}
      </SpeedDial>}
    </Box>
  )
}

export default AdminNav