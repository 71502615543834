import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, CardMedia, Button, FormControl, InputLabel, TextField, Fade, Slide, Grow, Zoom, Popover, Box, Stack, CircularProgress, Backdrop  } from '@mui/material';
import { Clear, FileUpload, Upload } from '@mui/icons-material'
import axios from 'axios';
import ImageUploader from 'react-image-upload';
import { updateBanner, updatePFP, updateUsername } from '../../utils/update';
import { BASE_URL } from '../../utils/Constants';

import imageCompression from 'browser-image-compression';

var options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true
}

const Settings_Profile = ({ Theme, userData }) => {

  const [Open, setOpen] = useState(false)
  const [Username, setUsername] = useState("Username")


  const [PFP, setPFP] = useState({})
  const [oldPFP, setoldPFP] = useState("")
  const [PFP_URL, setPFP_URL] = useState("")
  function setPFPLOGIC(imageFile) {
    setPFP(imageFile.file)
    setPFP_URL(imageFile.dataUrl)
  }
  function DeletePFPLOGIC() {
    setPFP({})
    setPFP_URL("")
  }

  const [Banner, setBanner] = useState({})
  const [oldBanner, setoldBanner] = useState("")
  const [Banner_URL, setBanner_URL] = useState("")
  function setBannerLOGIC(imageFile) {
    setBanner(imageFile.file)
    setBanner_URL(imageFile.dataUrl)
  }
  function DeleteBannerLOGIC() {
    setBanner({})
    setBanner_URL("")
  }

  useEffect(() => {

    if(userData.profile.avatar) {
      setoldPFP(userData.profile.avatar)
    }

    if(userData.profile.Banner) {
      setoldBanner(userData.profile.Banner)
    }

    if(userData.profile.username) {
      setUsername(userData.profile.username)
    }

  }, [userData])

  async function Save() {
    
    async function process() {

      if(Username != userData.profile.username) {
        setOpen(true);
       
        const Data = {
          userData, 
          username: Username   
        } 

        updateUsername(Data)
      }
      if(PFP_URL != "") {
        setOpen(true);
        setPFP_URL("")
        
        await imageCompression(PFP, options)
        .then(async function (compressedFile) {

        const formData = new FormData();
        formData.append('image', compressedFile)
  
        const { data } = await axios.post(BASE_URL + '/image/upload', formData)
        const URL = 'https://api.brooksiesgaming.com/uploads/' + data.filename
          
        const Data = {
          userData,
          PFP: URL
        }
        updatePFP(Data)
      })

      }
      if(Banner_URL != "") {
        setOpen(true);
        setBanner_URL("")

        await imageCompression(Banner, options)
        .then(async function (compressedFile) {

        const formData = new FormData();
        formData.append('image', compressedFile)
  
        const { data } = await axios.post(BASE_URL + '/image/upload', formData)
        const URL = 'https://api.brooksiesgaming.com/uploads/' + data.filename
          
        const Data = {
          userData,
          Banner: URL
        }
        updateBanner(Data)
      })
      }

    }

    await process()
    setTimeout(() => {
    setOpen(false)
      window.location.reload(true)
    }, [500])
  };


  return (
    <Box sx={{ height: '580px', width: { xs: '90vw', md: '50vw'}, borderBottomRightRadius: '25px', borderBottomLeftRadius: '25px', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center ' }}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={Open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Stack gap={2}>
      <Button variant="contained" sx={{ backgroundColor: 'blue', height:'50px'}} onClick={() => Save()}>Save</Button>
      <Stack direction="row" sx={{ height: '300px', width: { xs: '90vw', md: '50vw'}, backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center'}} gap={2}>
      <ImageUploader
      style={{  }}
      deleteIcon={<Box sx={{ width: '200px', display: `${PFP_URL != "" ? 'flex' : 'none'}`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${PFP_URL})`, alignItems: 'center', justifyContent: 'center', borderRadius: '25px'}} >
        <Clear sx={{ fontSize: '200px', color: '#fff' }}/>
      </Box>}
      uploadIcon={<Box sx={{ width: '200px', display: `${PFP_URL != "" ? 'none' : 'flex'}`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${oldPFP})`, alignItems: 'center', justifyContent: 'center', borderRadius: '25px'}} >
      <Upload sx={{ fontSize: '200px', color: '#fff' }}/>
    </Box>}
      onFileAdded={(img) => setPFPLOGIC(img)}
      onFileRemoved={(img) => DeletePFPLOGIC(img)}
      />

      <Box sx={{ width: '30vw'}}>

      <input id="account-name" style={{ height: '60px', width: '100%', backgroundColor: 'black', outline: 'none', border: 'none', paddingLeft: '10px', fontSize: '20px', color: '#fff', outline: '1px solid #fff' }} value={Username} type="name" onChange={(e) => setUsername(e.target.value)} />

      </Box>
      </Stack>

    <ImageUploader
      style={{  }}
      deleteIcon={<Box sx={{ width: { xs: '90vw', md: '50vw'}, display: `${Banner_URL != "" ? 'flex' : 'none'}`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${Banner_URL})`, alignItems: 'center', justifyContent: 'center', borderRadius: '25px'}} >
        <Clear sx={{ fontSize: '200px', color: '#fff' }}/>
      </Box>}
      uploadIcon={<Box sx={{ width: { xs: '90vw', md: '50vw'}, display: `${Banner_URL != "" ? 'none' : 'flex'}`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${oldBanner})`, alignItems: 'center', justifyContent: 'center', borderRadius: '25px'}} >
      <Upload sx={{ fontSize: '200px', color: '#fff' }}/>
    </Box>}
      onFileAdded={(img) => setBannerLOGIC(img)}
      onFileRemoved={(img) => DeleteBannerLOGIC(img)}
      />  
      </Stack>
        
    </Box>
  )
}

export default Settings_Profile