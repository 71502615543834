import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, CardMedia, Button, FormControl, InputLabel, TextField, Fade, Slide, Grow, Zoom, Popover, Box, Stack, Alert  } from '@mui/material';
import axios from 'axios';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSteam, faPlaystation, faXbox } from '@fortawesome/free-brands-svg-icons'
import DeleteIcon from '@mui/icons-material/Delete';
import { CreateLinkedAccount, DeleteLinkedAccount, clickOnAccount, getLinknedAccounts } from '../../utils/LinkedAccounts';

const Epic_Games_Icon = require('../../Assets/Epic_Games.png');

const LinkedAccounts = ({ Data }) => {

   const [State, setState] = useState("ListAccounts")
   const [Accounts, setAccounts] = useState([])

   async function HandleAccounts() {

    const maped = await Promise.all(Data.map(async(item, index) => {


        console.log(item)

        if(item.Platform === 'Steam') {

            if(!item.Username.includes('com/')) return {
                Platform: item.Platform,
                Username: item.Username,
                userID: item.userID,
                displayName: `An error has occured!`,
                _id: item._id
            }

            const Type = item.Username.split("com/")[1].split('/')[0];

            if(Type === 'profiles') {

                return {
                    Platform: item.Platform,
                    Username: item.Username,
                    userID: item.userID,
                    displayName: item.Username.split("/profiles/")[1].replace('/', '').replace('/', '').toUpperCase(),
                    _id: item._id
                }

            } else if(Type === 'id') {

                return {
                    Platform: item.Platform,
                    Username: item.Username,
                    userID: item.userID,
                    displayName: item.Username.split("/id/")[1].replace('/', '').replace('/', '').toUpperCase(),
                    _id: item._id
                }

            } else {

                return {
                    Platform: item.Platform,
                    Username: item.Username,
                    userID: item.userID,
                    displayName: 'An Error Has Occured!',
                    _id: item._id
                }

            }


        } else {

            return {
                Platform: item.Platform,
                Username: item.Username,
                userID: item.userID,
                displayName: item.Username,
                _id: item._id
            }

        }



    }))

    return setAccounts(maped)
    
   }

   useEffect(() => {

    HandleAccounts()

   }, [Data])

  return (
    <Box sx={{ height: '635px', width: { xs: '90vw', md: '50vw'}, borderRadius: '25px' }}>
        <Stack sx={{ width: { xs: '90vw', md: '50vw'}, height: '510px', display: 'flex', alignItems: 'center', overflow: 'scroll', paddingTop: '20px'}} gap={1}>

        {Accounts.map((item, index) => (

        <Box sx={{ backgroundColor: '', width: '30vw', height: '50px', display: 'flex', justifyContent: 'center'}}>
        {item.Platform === "Epic_Games" && <Stack direction="row" gap={1}>
        <img src={Epic_Games_Icon} style={{ height: '50px', paddingLeft: '2px' }} />
        <Box sx={{ width: '30vw', backgroundColor: 'blue', height: '50px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}} onClick={() => clickOnAccount(item)}>
            <Typography sx={{ color: '#fff', fontFamily: 'Impact', fontSize: '30px'}}>{item.displayName.toUpperCase()}</Typography>
        </Box>
        
        </Stack>}
        {item.Platform === "Steam" && <Stack direction="row" gap={1}>
        <FontAwesomeIcon icon={faSteam} style={{ color: `#fff`, fontSize: '50px'}} />
        <Box sx={{ width: '30vw', backgroundColor: 'blue', height: '50px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}} onClick={() => clickOnAccount(item)}>
            <Typography sx={{ color: '#fff', fontFamily: 'Impact', fontSize: '30px'}}>{item.displayName.toUpperCase()}</Typography>
        </Box>
        
        </Stack>}
        {item.Platform === "PlayStation" && <Stack direction="row" gap={1}>
        <FontAwesomeIcon icon={faPlaystation} style={{ color: `#fff`, fontSize: '45px'}} />
        <Box sx={{ width: '30vw', backgroundColor: 'blue', height: '50px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}} onClick={() => clickOnAccount(item)}>
            <Typography sx={{ color: '#fff', fontFamily: 'Impact', fontSize: '30px'}}>{item.displayName.toUpperCase()}</Typography>
        </Box>
        
        </Stack>}
        {item.Platform === "XBOX" && <Stack direction="row" gap={1}>
        <FontAwesomeIcon icon={faXbox} style={{ color: `#fff`, fontSize: '50px'}} />
        <Box sx={{ width: '30vw', backgroundColor: 'blue', height: '50px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}} onClick={() => clickOnAccount(item)}>
            <Typography sx={{ color: '#fff', fontFamily: 'Impact', fontSize: '30px'}}>{item.displayName.toUpperCase()}</Typography>
        </Box>
        
        </Stack>}
        </Box>

        ))}


        </Stack>
    </Box>
  )
}

export default LinkedAccounts