import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, CardMedia, Button, FormControl, InputLabel, TextField, Fade, Slide, Grow, Zoom, Popover, Box, Stack  } from '@mui/material';
import axios from 'axios';

import SettingsIcon from '@mui/icons-material/Settings';
import Person2Icon from '@mui/icons-material/Person2';
import SecurityIcon from '@mui/icons-material/Security';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { Settings_Profile } from './';

const Settings = ({ Theme, userData }) => {

    const [State, setState] = useState("PROFILE")

  return (
    <Box sx={{ height: '635px', width: { xs: '90vw', md: '50vw'}, borderRadius: '25px' }}>

    <Stack gap={0}>
    <Box sx={{ width: { xs: '90vw', md: '50vw'}, height: '50px', backgroundColor: '', borderTopRightRadius: '25px', borderTopLeftRadius: '25px', borderBottom: `5px solid ${Theme.BorderColor}`}}>
                <Stack direction="row" sx={{ width: { xs: '90vw', md: '50vw'}, height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box sx={{ backgroundColor: '', display: { xs: 'none', md: 'none'} , ":hover": { cursor: 'pointer'}}} onClick={() => console.log('test')}>
                        <Stack direction="row" gap={1} sx={{ width: { xs: '90vw', md: '50vw'}, height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <SettingsIcon sx={{ color: `#fff`}} /> <Typography sx={{ fontFamily: 'Impact', fontSize: '25px', color: `${Theme.TextColor}`}}>ViEW CATEGORIES</Typography>
                        </Stack>
                    </Box>

                    <Box sx={{ backgroundColor: '', borderRight: `5px solid ${Theme.BorderColor}`, borderLeft: `5px solid ${Theme.BorderColor}`, ":hover": { backgroundColor: 'darkblue', cursor: 'pointer'}}} onClick={() => setState('PROFILE')}>
                        <Stack direction="row" gap={1} sx={{  width: { xs: '25vw', md: '13.33vw'}, height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', display: { xs: 'flex', md: 'flex'}, overflow: 'clip'  }} >
                        <Person2Icon sx={{ color: `#fff`, fontSize: '30px'}} /> <Typography sx={{ fontFamily: 'Impact', fontSize: '25px', color: `${Theme.TextColor}`, display: { xs: 'none', md: 'none', xl: 'unset'}}}>PROFILE</Typography>
                        </Stack>
                    </Box>
                    {/* <Box sx={{ backgroundColor: '', borderRight: `5px solid ${Theme.BorderColor}`, display: { xs: 'none', md: 'flex'}, ":hover": { backgroundColor: 'darkblue', cursor: 'pointer'} }}>
                        <Stack direction="row" gap={1} sx={{  width: '10vw', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', display: { xs: 'none', md: 'flex'}, overflow: 'clip'  }}>
                        <SettingsIcon sx={{ color: `#fff`}} /> <Typography sx={{ fontFamily: 'Impact', fontSize: '25px', color: `${Theme.TextColor}`, display: { xs: 'none', md: 'none', xl: 'flex'}}}>GENERAL</Typography>
                        </Stack>
                    </Box> */}
                    <Box sx={{ backgroundColor: '', borderRight: `5px solid ${Theme.BorderColor}`, ":hover": { backgroundColor: 'darkblue', cursor: 'pointer'}}} onClick={() => setState('THEME')}>
                        <Stack direction="row" gap={1} sx={{  width: { xs: '25vw', md: '13.33vw'}, height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', display: { xs: 'flex', md: 'flex'}, overflow: 'clip' }}>
                        <ColorLensIcon sx={{ color: `#fff`}} /> <Typography sx={{ fontFamily: 'Impact', fontSize: '25px', color: `${Theme.TextColor}`, display: { xs: 'none', md: 'none', xl: 'unset'}}}>THEME</Typography>
                        </Stack>
                    </Box>
                    <Box sx={{ backgroundColor: '', borderRight: `5px solid ${Theme.BorderColor}`, ":hover": { backgroundColor: 'darkblue', cursor: 'pointer'}}} onClick={() => setState('SECURITY')}>
                        <Stack direction="row" gap={1} sx={{  width: { xs: '25vw', md: '13.33vw'}, height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', display: { xs: 'flex', md: 'flex'}, overflow: 'clip'  }}>
                        <SecurityIcon sx={{ color: `#fff`}} /> <Typography sx={{ fontFamily: 'Impact', fontSize: '25px', color: `${Theme.TextColor}`, display: { xs: 'none', md: 'none', xl: 'unset'}}}>SECURITY</Typography>
                        </Stack>
                    </Box>
                </Stack>
    </Box>
        {State === "PROFILE" && <Settings_Profile Theme={Theme} userData={userData} />   }     
    </Stack>
        
    </Box>
  )
}

export default Settings