import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, CardMedia, Button, FormControl, InputLabel, TextField, Fade, Slide, Grow, Zoom, Popover, Box, Stack, BottomNavigation, BottomNavigationAction, Backdrop, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Switch, Snackbar, Paper, List, ListItem, Checkbox, ListItemText, ListItemIcon, Grid  } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ExpandMore, Delete, Add, GroupWork } from '@mui/icons-material'
import axios from 'axios';
import { PageNotFound } from '../../components/General';
import { TextFields } from '@mui/icons-material';


function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1)
}


const Tournament_Edit = ({ PermLevel }) => {

  const { id } = useParams();

  const [RenderPage, setRenderPage] = useState(false)
  const [Loading, setLoading] = useState(true)

  const [Data, setData] = useState({})

  const [BottomBarOptions, setBottomBarOptions] = useState([
  {
    ID: 1,
    Icon: 'Groups',
    Name: 'Teams',
    Type: 'Teams'
  },
  {
    ID: 2,
    Icon: 'Mediation',
    Name: 'Bracket',
    Type: 'Playoffs'
  },
  {
    ID: 3,
    Icon: 'Leaderboard',
    Name: 'Player Stats',
    Type: 'Stats_Player'
  },
  {
    ID: 4,
    Icon: 'Leaderboard',
    Name: 'Team Stats',
    Type: 'Stats_Team'
  }])

  async function HandleBottomBar(data) {

    var Data = BottomBarOptions;

    await data.map(async(item, index) => {

      Data = Data.filter((a) => a.ID != item.ID)

    })

    setBottomBarOptions(Data)
    setLeft(Data)
    setRight(data)

  }


  async function getData(ID) {

    const { data } = await axios.get(`https://api.brooksiesgaming.com/tourney/${ID}`);
    setData(data)
    setRenderPage(true)
    setLoading(false)

    HandleBottomBar(data.Config.BottomBar)
  }

  useEffect(() => {

   if(PermLevel === "") {
    setTimeout(() => {
        setLoading(false)
    }, 2000)
   } else {
    if(PermLevel === "FOUNDER") {
        getData(id)
      } else {
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      }
   }

  }, [PermLevel])

  const [Checked, setChecked] = useState([])
  const [Left, setLeft] = useState([])
  const [Right, setRight] = useState([])

  const leftChecked = intersection(Checked, Left);
  const rightChecked = intersection(Checked, Right);

  useEffect(() => {

    if(!Data.Config) return;

    let newArr = Data.Config;

    newArr.BottomBar = Left

    setData({
      ...Data,
      Config: newArr
    })

  }, [Left, Right])

  const handleToggle = value => () => {
    const currentIndex = Checked.indexOf(value)
    const newChecked = [...Checked]
  
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
  
    setChecked(newChecked)
  }

  const handleAllRight = () => {
    setRight(Right.concat(Left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(Right.concat(leftChecked));
    setLeft(not(Left, leftChecked));
    setChecked(not(Checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(Left.concat(rightChecked));
    setRight(not(Right, rightChecked));
    setChecked(not(Checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(Left.concat(Right));
    setRight([]);
  };

  const customList = items => (
  <Paper sx={{ width: 200, height: 230, overflow: "auto" }}>
    <List dense component="div" role="list">
      {items.map(value => {
        const labelId = `transfer-list-item-${value}-label`

        return (
          <ListItem
            key={value.Name}
            role="listitem"
            button
            onClick={handleToggle(value)}
          >
            <ListItemIcon>
              <Checkbox
                checked={Checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  "aria-labelledby": labelId
                }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={value.Name} />
          </ListItem>
        )
      })}
    </List>
  </Paper>
  )

  return (
    <Box>
    {Loading === true && 
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={Loading === true}
    >
    <CircularProgress color="inherit" />
    </Backdrop>}
    {Loading === false &&  
    <Box>
    {RenderPage === false && <PageNotFound />}
    {RenderPage === true && 
    <Box><Stack sx={{ minHeight: '100vh', width: '104vw', display: 'flex', alignItems: 'center', backgroundColor: '', paddingTop: '50px'}}>

    <Box sx={{ width: '104vw', height: '100px', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

        <Stack direction="row" gap={5}>
            <Stack>
            <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>Name: </Typography>
            <TextField value={Data.Name} onChange={(e) => {
              setData({
                ...Data,
                Name: e.target.value
              })
            }} sx={(theme) => {

              return {
                outline: '1px solid blue',
                backgroundColor: '#00032b',

                "& .MuiInputBase-root": {
                  color: '#fff'
                }
              }

            }}/>
            </Stack>

            <Stack>
            <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>Tag: </Typography>
            <TextField value={Data.Tag} onChange={(e) => {
              setData({
                ...Data,
                Tag: e.target.value
              })
            }} sx={(theme) => {

              return {
                outline: '1px solid blue',
                backgroundColor: '#00032b',

                "& .MuiInputBase-root": {
                  color: '#fff'
                }
              }

            }}/>
            </Stack>

            <Stack>
            <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>ID: </Typography>
            <TextField value={Data.ID} onChange={(e) => {
              setData({
                ...Data,
                ID: e.target.value
              })
            }} sx={(theme) => {

              return {
                outline: '1px solid blue',
                backgroundColor: '#00032b',

                "& .MuiInputBase-root": {
                  color: '#fff'
                }
              }

            }}/>
            </Stack>

            <Stack>
            <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>Status: </Typography>
            <Switch checked={Data.Status} onClick={() => {

              if(Data.Status === true) {
                setData({
                  ...Data,
                  Status: false
                })
              } else {
                setData({
                  ...Data,
                  Status: true
                })
              }

            }}/>
            <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: '30px'}}>{String(Data.Status)}</Typography>
            </Stack>
        </Stack>
    </Box>

    <Stack sx={{ width: '104vw', minHeight: '100px', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '20px'}} gap={1}>
    <Box sx={{ paddingBottom: '20px'}}>
    <Button variant='outlined' sx={{ width: '25vw', outline: '1px solid #fff', color: '#fff'}} onClick={() => {

      let newArr = Data.Rules
      newArr = newArr.concat({
        Name: 'Rule Category',
        Rules: [
          {
            ID: 1,
            Text: 'New Rule'
          }
        ]
      })

      setData({
        ...Data,
        Rules: newArr
      })

      console.log(newArr)

    }}>Add Rule Category</Button>
    </Box>
      {Data.Rules.map((item, index) => (

      <Stack direction="row">
        <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header" sx={{ width: '50vw'}}>
        <Typography><TextField value={item.Name} onChange={(e) => {

          let newArr = Data.Rules;

          newArr[index].Name = e.target.value

          setData({
            ...Data,
            Rules: newArr
          })

        }}/></Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'flex', textAlign: 'left'}}>
        <Stack gap={2}>
        {item.Rules.map((item, idx) => (
              <Box>
                <Typography key={idx}>
                •<TextField value={item.Text} onChange={(e) => {

              let newArr = Data.Rules
              newArr[index].Rules[idx].Text = e.target.value

              setData({
                ...Data,
                Rules: newArr
              })

              }}/>
              <Delete sx={{ color: 'red', fontSize: '50px', ":hover": { cursor: 'pointer', color: 'red' }}} onClick={() => {

              let newArr = Data.Rules

              newArr[index].Rules = newArr[index].Rules.filter((a) => a != item);
              setData({
                ...Data,
                Rules: newArr
              })

              
              }}/>
            </Typography> 
            </Box>
        ))}
        <Button variant='outlined' sx={{ outline: '1px solid blue', color: 'blue', float: 'right'}} onClick={() => {

          let newArr = Data.Rules
          
          newArr[index].Rules = newArr[index].Rules.concat({
            ID: 1,
            Text: 'New Rule'
          })

          setData({
            ...Data,
            Rules: newArr
          })
        }}>Add Rule</Button>
        </Stack>
        </AccordionDetails>
        </Accordion>
        <Delete sx={{ color: '#fff', fontSize: '50px', ":hover": { cursor: 'pointer', color: 'red' }}} onClick={() => {

          let newArr = Data.Rules;

          newArr = newArr.filter(a => a != item)

          setData({
            ...Data,
            Rules: newArr
          })

        }}/>
      </Stack>
      ))}
    </Stack>

    <Stack sx={{ width: '104vw', minHeight: '200px', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '20px'}} gap={1}>
      
      <Stack direction="row">
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header" sx={{ width: '50vw'}}>
            <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: '20px', color: 'black'}}>Config | Players</Typography>
          </AccordionSummary>
            <AccordionDetails>
            <Stack direction="row">
              <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: '25px', color: 'black'}}>Players Can Join: </Typography>
              <Switch checked={true} onClick={() => {

                // if(Data.Status === true) {
                //   setData({
                //     ...Data,
                //     Status: false
                //   })
                // } else {
                //   setData({
                //     ...Data,
                //     Status: true
                //   })
                // }

              }}/>
            </Stack>
            </AccordionDetails>
            <AccordionDetails>
            <Stack direction="row">
              <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: '25px', color: 'black'}}>Players Need Accepting: </Typography>
              <Switch checked={true} onClick={() => {

                // if(Data.Status === true) {
                //   setData({
                //     ...Data,
                //     Status: false
                //   })
                // } else {
                //   setData({
                //     ...Data,
                //     Status: true
                //   })
                // }

              }}/>
            </Stack>
            </AccordionDetails>
        </Accordion>
        <Delete sx={{ color: 'black', fontSize: '50px', ":hover": { cursor: '', color: 'black' }}} />
      </Stack>

      <Stack direction="row">
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header" sx={{ width: '50vw'}}>
            <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: '20px', color: 'black'}}>Config | Teams</Typography>
          </AccordionSummary>
            <AccordionDetails>
            <Stack direction="row">
              <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: '25px', color: 'black'}}>Teams Can Join: </Typography>
              <Switch checked={true} onClick={() => {

                // if(Data.Status === true) {
                //   setData({
                //     ...Data,
                //     Status: false
                //   })
                // } else {
                //   setData({
                //     ...Data,
                //     Status: true
                //   })
                // }

              }}/>
            </Stack>
            </AccordionDetails>
            <AccordionDetails>
            <Stack direction="row">
              <Typography sx={{ color: '#fff', fontFamily: 'Boomer Tantrum', fontSize: '25px', color: 'black'}}>Team Details: </Typography>
              <Switch checked={true} onClick={() => {

                // if(Data.Status === true) {
                //   setData({
                //     ...Data,
                //     Status: false
                //   })
                // } else {
                //   setData({
                //     ...Data,
                //     Status: true
                //   })
                // }

              }}/>
            </Stack>
            </AccordionDetails>
        </Accordion>
        <Delete sx={{ color: 'black', fontSize: '50px', ":hover": { cursor: '', color: 'black' }}} />
      </Stack>
    </Stack>

    <Box sx={{ width: '104vw', minHeight: '200px', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '20px'}} >
    <Grid container spacing={2} justifyContent="center" alignItems="center">
     <Stack direction="row" gap={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
     <Stack>
      <Typography sx={{ color: '#fff'}}>Not Selected</Typography>
      <Grid item>{customList(Left)}</Grid>
      </Stack>
      <Stack>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5, outline: '1px solid blue', backgroundColor: '#00032b' }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={Left.length === 0}
            aria-label="move all right"
          >
            <Typography sx={{ color: `${Left.length > 0 ? '#fff' : 'grey'}`}}>≫</Typography>
          </Button>
          <Button
            sx={{ my: 0.5, outline: '1px solid blue', backgroundColor: '#00032b' }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            <Typography sx={{ color: `${leftChecked.length > 0 ? '#fff' : 'grey'}`}}>&gt;</Typography>
          </Button>
          <Button
            sx={{ my: 0.5, outline: '1px solid blue', backgroundColor: '#00032b' }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            <Typography sx={{ color: `${rightChecked.length > 0 ? '#fff' : 'grey'}` }}>&lt;</Typography>
          </Button>
          <Button
            sx={{ my: 0.5, outline: '1px solid blue', backgroundColor: '#00032b' }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={Right.length === 0}
            aria-label="move all left"
          >
            <Typography sx={{ color: `${Right.length > 0 ? '#fff' : 'grey'}`}}>≪</Typography>
          </Button>
        </Grid>
      </Grid>
      </Stack>
      <Stack>
      <Typography sx={{ color: '#fff'}}>Selected</Typography>
      <Grid item>{customList(Right)}</Grid>
      </Stack>
     </Stack>
    </Grid>
    </Box>
    <Box>
    </Box>
      
    </Stack>
    
    </Box>}
    </Box>}
    </Box>
  )
}

export default Tournament_Edit