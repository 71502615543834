import { Box, Button, Dialog, Fade, Menu, MenuItem, Slide, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import MenuIcon from '@mui/icons-material/Menu';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import LoginIcon from '@mui/icons-material/Login';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import MicIcon from '@mui/icons-material/Mic';
import CloseIcon from '@mui/icons-material/Close';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';



import { authorizeUser } from '../../utils/supabase';

const Closed = {
  Status: 'Closed',
  Width: '80px', 
  MenuIcon: <MenuIcon sx={{ color: '#fff', fontSize: '40px', ":hover": { cursor: 'pointer'}}} />,
  Bottom: '5px',
  PaddingLeft: '10px'
  
}

const Open = {
  Status: 'Open',
  Width: '250px', 
  MenuIcon: <KeyboardDoubleArrowLeftIcon sx={{ color: '#fff', fontSize: '40px', ":hover": { cursor: 'pointer'}}} />,
  Bottom: '0',
  PaddingLeft: '0px'
}

const Options = [
  {
    Name: 'Home',
    Path: '/',
    Icon: <HomeIcon sx={{ fontSize: '35px', padding: '10px', borderRadius: '10px', ":hover": { color: 'black', backgroundColor: '#fff', cursor: 'pointer' }}} />,
    Icon1: <HomeIcon sx={{ fontSize: '35px' }}/>
  },
  // {
  //   Name: 'Rules',
  //   Path: '/Rules',
  //   Icon: <MenuBookIcon sx={{ fontSize: '35px', padding: '10px', borderRadius: '10px', ":hover": { color: 'black', backgroundColor: '#fff', cursor: 'pointer' }}} />,
  //   Icon1: <MenuBookIcon sx={{ fontSize: '35px' }}/>
  // },
  // {
  //   Name: 'Podcast',
  //   Path: '/Podcast',
  //   Icon: <MicIcon sx={{ fontSize: '35px', padding: '10px', borderRadius: '10px', ":hover": { color: 'black', backgroundColor: '#fff', cursor: 'pointer' }}} />,
  //   Icon1: <MicIcon sx={{ fontSize: '35px' }}/>
  // },
  {
    Name: 'Rank System',
    Path: '/RankedSystem',
    Icon: <LeaderboardIcon sx={{ fontSize: '35px', padding: '10px', borderRadius: '10px', ":hover": { color: 'black', backgroundColor: '#fff', cursor: 'pointer' }}} />,
    Icon1: <LeaderboardIcon sx={{ fontSize: '35px' }}/>
  }
]

const NavBar = ({ userData, Tourneys }) => {

  const [MobileDialog, setMobileDialog] = useState(false)
  const [MobileProfileDropDown, setMobileProfileDropDown] = useState(false)

  const [Details, setDetails] = useState(Closed)
  const [LoggedIn, setLoggedIn] = useState(false)
  const [Path, setPath] = useState("")

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;


  const [Avatar, setAvatar] = useState("https://cdn.discordapp.com/embed/avatars/1.png");
  const [Username, setUsername] = useState("LOGIN")

  const [Style, setStyle] = useState({
    backgroundColor: '#11101d',
    Options: {
      Highlighted: {

      },
      Not_Selected: {

      },
      Selected: {

      }
    }
  })

  useEffect(() => {

    if(userData.userID) {
      setLoggedIn(true)
      setAvatar(userData.profile.avatar)
      setUsername(userData.profile.username)
    }

  }, [userData])

  async function changeState() {

    if(Details.Status === "Closed") {
      setDetails(Open)
    } else if(Details.Status === "Open") {
      setDetails(Closed)
    } else {
      return console.log('Error')
    }

  }

  async function login(Location) {

    if(LoggedIn === false) {

      authorizeUser()
       
    } else {
      if(Location != undefined) {
        navigate('/account?option=' + Location)
      } else {
      navigate('/account')
      }

      setMobileProfileDropDown(false)
    }

  }
  
  useEffect(() => {

    setPath(path)

  }, [path])

  return (
    <Box>

      <Box sx={{ position: 'fixed', top: 0, width: '100vw', height: '50px', backgroundColor: Style.backgroundColor, display: { xs: 'flex', md: 'none'}, alignItems: 'center', justifyContent: 'center', borderBottom: '3px solid #fff'}}>
      <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '50px'}}>
      <Box sx={{ position: 'fixed', left: 10}} onClick={() => setMobileDialog(true)}>
      {Details.MenuIcon}
      </Box>
      <Dialog
        open={MobileDialog}
      >
        <Stack sx={{ backgroundColor: 'black'}} gap={1}>
        {Options.map((item, index) => (

        <Slide direction="down" in={MobileDialog} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}>
        <Box sx={{ width: '200px', height: '35px', color: `${Path === item.Path ? 'black' : '#fff'}`, fontSize: '35px', backgroundColor: `${Path === item.Path ? '#fff' : '#19172A'}`, padding: '10px', borderRadius: '10px', ":hover": { color: 'black', backgroundColor: '#fff', cursor: 'pointer' }, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

        <Stack direction="row" onClick={() => {
          setMobileDialog(false)
          navigate(item.Path)
        }}>
        <Box sx={{ width: '50px', height: '50px', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {item.Icon1}
        </Box>
        <Box sx={{ width: '150px', height: '50px', backgroundColor: '', display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ fontFamily: 'Impact', fontSize: '20px'}}>{item.Name}</Typography>
        </Box>
        </Stack>
        </Box>
        
        </Slide>

        ))}

    <hr style={{ width: '200px', transition: 'all 0.5s ease'}}/>

    {Tourneys.map((item, index) => (

    <Box>
      {item.Status === true && <Slide direction="down" in={MobileDialog} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}>
    <Box sx={{ width: '200px', height: '35px', color: `${Path === `/tourney/${item.ID}/dashboard` ? 'black' : '#fff'}`, fontSize: '35px', backgroundColor: `${Path === `/tourney/${item.ID}/dashboard` ? '#fff' : '#19172A'}`, padding: '10px', borderRadius: '10px', ":hover": { color: 'black', backgroundColor: '#fff', cursor: 'pointer' }, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

    <Stack direction="row" onClick={() => {
      setMobileDialog(false)
      navigate(`/tourney/${item.ID}/dashboard`)
    }}>
    <Box sx={{ width: '50px', height: '50px', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <img src={item.Logo} style={{ height: '50px', width: '50px', padding: '2px'}} />
    </Box>
    <Box sx={{ width: '150px', height: '50px', backgroundColor: '', display: 'flex', alignItems: 'center' }}>
    <Typography sx={{ fontFamily: 'Impact', fontSize: '20px'}}>{item.Tag}</Typography>
    </Box>
    </Stack>
    </Box>

    </Slide>}
    </Box>

      ))}

        
        
        <Box sx={{ paddingTop: '10px'}}>
        <Slide direction="down" in={MobileDialog} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}>
        <Box sx={{ width: '200px', height: '35px', color: `#fff`, fontSize: '35px', backgroundColor: `#19172A`, padding: '10px', borderRadius: '10px', ":hover": { color: 'black', backgroundColor: '#fff', cursor: 'pointer' }, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

        <Stack direction="row" onClick={() => setMobileDialog(false)}>
        <Box sx={{ width: '50px', height: '50px', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CloseIcon sx={{ fontSize: '35px', padding: '10px', borderRadius: '10px', ":hover": { color: 'black', backgroundColor: '#fff', cursor: 'pointer' }}} />
        </Box>
        <Box sx={{ width: '150px', height: '50px', backgroundColor: '', display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ fontFamily: 'Impact', fontSize: '20px'}}>Close</Typography>
        </Box>
        </Stack>

        </Box>
        </Slide>
        </Box>

        </Stack>
        

      </Dialog>
      <Box sx={{ position: 'fixed', right: 10}}>
      <Button variant='contained' onClick={() => setMobileProfileDropDown(true)}>Account</Button>
      {MobileProfileDropDown === true &&
      <Menu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={true}
      onClose={() => setMobileProfileDropDown(false)}
      >
      <MenuItem onClick={() => login()}>
        <Typography textAlign="center">Linked Accounts</Typography>
      </MenuItem>
      <MenuItem onClick={() => login("social_accounts")}>
        <Typography textAlign="center">Social Accounts</Typography>
      </MenuItem>
      <MenuItem onClick={() => login("stats_total")}>
        <Typography textAlign="center">Statistics Total</Typography>
      </MenuItem>
      <MenuItem onClick={() => login("stats_average")}>
        <Typography textAlign="center">Statistics Average</Typography>
      </MenuItem>
      <MenuItem onClick={() => login("settings")}>
        <Typography textAlign="center">Settings</Typography>
      </MenuItem>
      </Menu>}
      </Box>
      </Stack>
      </Box>

<Stack sx={{ width: Details.Width, height: '100%', borderRight: '3px solid #fff', backgroundColor: Style.backgroundColor, position: 'fixed', left: 0, top: 0, zIndex: 99, transition: 'all 0.5s ease', display: { xs: 'none', md: 'unset'} }}>

<Box sx={{ height: '60px', display: 'flex', alignItems: 'center', position: 'relative', justifyContent: 'center'}} onClick={() => changeState()}>
  {Details.Status === "Open" && <Fade in={Details.Status === "Open"} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
  <Stack direction="row" sx={{ height: '60px', width: Details.Width, display: 'flex', alignItems: 'center', justifyContent: 'center' }} gap={5}>
   {/* <Box>
      <img src="https://imgur.com/EX8kjrp.png" style={{ height: '50px' }} />
  </Box>
  <Box>
    <Typography sx={{ color: '#fff', fontFamily: 'Trebuchet MS', fontSize: '30px'}}>LOC</Typography>
  </Box> */}
  <Box>
    {Details.MenuIcon}
  </Box>
  </Stack>
  </Fade>}
  {Details.Status === "Closed" && <Box>
      {Details.MenuIcon}
  </Box>}
</Box>

<Box>

</Box>

<Stack gap={0} sx={{ height: '85vh', width: Details.Width, display: 'flex', backgroundColor: '', overflow: 'scroll', paddingTop: '10px' }}>

{Options.map((item, index) => (

<Box sx={{ height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={() => navigate(item.Path)}>
{Details.Status === 'Closed' && <Box sx={{ color: `${Path === item.Path ? 'black' : '#fff'}`, backgroundColor: `${Path === item.Path ? '#fff' : '#19172A'}`, borderRadius: '10px'}}>{item.Icon}</Box>}
{Details.Status === 'Open' && <Slide direction="right" in={Details.Status === "Open"} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}>
<Box sx={{ width: '200px', height: '35px', color: `${Path === item.Path ? 'black' : '#fff'}`, fontSize: '35px', backgroundColor: `${Path === item.Path ? '#fff' : '#19172A'}`, padding: '10px', borderRadius: '10px', ":hover": { color: 'black', backgroundColor: '#fff', cursor: 'pointer' }, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

<Stack direction="row">
<Box sx={{ width: '50px', height: '50px', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
{item.Icon1}
</Box>
<Box sx={{ width: '150px', height: '50px', backgroundColor: '', display: 'flex', alignItems: 'center' }}>
<Typography sx={{ fontFamily: 'Impact', fontSize: '20px'}}>{item.Name}</Typography>
</Box>
</Stack>

</Box>
</Slide>}
</Box>

))}

<hr style={{ width: Details.Width, transition: 'all 0.5s ease'}}/>

{Tourneys.map((item, index) => (

  <Box>
  {item.Status === true && <Box sx={{ height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={() => navigate(`/tourney/${item.ID}/dashboard`)}>
  {Details.Status === 'Closed' && <Box sx={{ color: `${Path === `/tourney/${item.ID}/*` ? 'black' : '#fff'}`, backgroundColor: `${Path === `/tourney/${item.ID}/dashboard` ? '#fff' : '#19172A'}`, borderRadius: '10px', ":hover": { color: 'black', backgroundColor: '#fff', cursor: 'pointer' }}}><img src={item.Logo} style={{ height: '50px', width: '50px', padding: '2px'}} /></Box>}
  {Details.Status === 'Open' && <Slide direction="right" in={Details.Status === "Open"} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}>
  <Box sx={{ width: '200px', height: '35px', color: `${Path === `/tourney/${item.ID}/dashboard` ? 'black' : '#fff'}`, fontSize: '35px', backgroundColor: `${Path === `/tourney/${item.ID}/dashboard` ? '#fff' : '#19172A'}`, padding: '10px', borderRadius: '10px', ":hover": { color: 'black', backgroundColor: '#fff', cursor: 'pointer' }, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

  <Stack direction="row">
  <Box sx={{ width: '50px', height: '50px', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <img src={item.Logo} style={{ height: '50px', width: '50px'}} />
  </Box>
  <Box sx={{ width: '150px', height: '50px', backgroundColor: '', display: 'flex', alignItems: 'center' }}>
  <Typography sx={{ fontFamily: 'Impact', fontSize: '20px'}}>{item.Tag}</Typography>
  </Box>
  </Stack>

  </Box>
  </Slide>}
  </Box>}

  </Box>
))}

</Stack>


<Box sx={{ height: '80px', display: 'flex', alignItems: 'center', position: 'fixed', justifyContent: 'center', bottom: Details.Bottom, paddingLeft: Details.PaddingLeft}} onClick={() => login()}>
    {Details.Status === 'Closed' && <LoginIcon sx={{ color: '#fff', fontSize: '35px', backgroundColor: '#19172A', padding: '10px', borderRadius: '10px', ":hover": { color: 'black', backgroundColor: '#fff', cursor: 'pointer' }}} />}
    {Details.Status === 'Open' && <Fade in={Details.Status === "Open"} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
    <Box sx={{ backgroundColor: '#19172A', width: Details.Width, height: '80px', display: 'flex', alignItems: 'center', ":hover": { cursor: 'pointer' }}}>
    
    <Box sx={{ paddingLeft: '10px'}}>

      <Box sx={{ height: '50px', width: '50px', borderRadius: '10px', backgroundColor: 'Black', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: `url(${Avatar})`}}>

      </Box>
    </Box>
    <Box sx={{ paddingLeft: '10px', width: '200px', overflow: 'hidden'}}>
      <Stack>
      <Typography sx={{ color: '#fff', fontSize: 'auto', fontFamily: 'Impact'}}>{Username.toUpperCase()}</Typography>
      {userData.userID && <Typography sx={{ color: '#fff', fontSize: '15px', fontFamily: 'Impact'}}>#{userData.userID}</Typography>}

      </Stack>
    </Box>

    <LoginIcon sx={{ color: '#fff', fontSize: '25px', ":hover": { cursor: 'pointer'}, backgroundColor: '#19172A', padding: '10px', borderRadius: '10px'}} />

  </Box></Fade>}
</Box>
  
      </Stack>
    </Box>
  )
}

export default NavBar