import { SupabaseAuthClient } from '@supabase/supabase-js/dist/module/lib/SupabaseAuthClient';
import { createClient } from '@supabase/supabase-js';
import { Auth, ThemeSupa } from '@supabase/auth-ui-react';
import axios from 'axios';
import { Supabase_KEY, Supabase_URL } from './Constants';

const supabase = createClient(
    Supabase_URL,
    Supabase_KEY
)

export async function getUser() {

    const data = await supabase.auth.getUser();
    return data.data.user.identities[0].identity_data

}

export async function authorizeUser() {

    const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'discord'
    })

    if(data) {
        console.log(data)
    } else (
        console.log(error)
    )

}