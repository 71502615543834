import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {

    const navigate = useNavigate()

  return (
    <Box sx={{ height: '100vh', width: { xs: '100vw', md: '108vw'}, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

        <Stack>

            <Typography sx={{ fontFamily: 'Impact', fontSize: '10vw', color: '#fff'}}>404 - Page Not Found</Typography>
            <Button variant="outlined" onClick={() => navigate('/')}>HomePage</Button>

        </Stack>

    </Box>
  )
}

export default PageNotFound