import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, CardMedia, Button, FormControl, InputLabel, TextField, Fade, Slide, Grow, Zoom, Popover, Box, Stack, BottomNavigation, BottomNavigationAction, Backdrop, CircularProgress  } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PageNotFound } from '../../components/General';

const Tournament_Dashboard = ({ PermLevel }) => {

  const [RenderPage, setRenderPage] = useState(false)
  const [Loading, setLoading] = useState(true)

  async function getData() {

    setRenderPage(true)
    setLoading(false)

  }

  useEffect(() => {

   if(PermLevel === "") {
    setTimeout(() => {
        setLoading(false)
    }, 2000)
   } else {
    if(PermLevel === "FOUNDER") {
        getData()
      } else {
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      }
   }

  }, [PermLevel])

  return (
    <Box>
    {Loading === true && 
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={Loading === true}
    >
    <CircularProgress color="inherit" />
    </Backdrop>}
    {Loading === false && 
    <Box>
    {RenderPage === false && <PageNotFound />}
    {RenderPage === true && 
    <Stack sx={{minHeight: '100vh', width: '104vw', display: 'flex', alignItems: 'center', backgroundColor: '', paddingTop: '50px'}}>

    <Button variant='contained' sx={{ width: '40vw', height: '50px'}} >CREATE</Button> 
      
    </Stack>}
    </Box>}
    </Box>
  )
}

export default Tournament_Dashboard