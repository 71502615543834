import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, CardMedia, Button, FormControl, InputLabel, TextField, Fade, Slide, Grow, Zoom, Popover, Box, Stack, BottomNavigation, BottomNavigationAction  } from '@mui/material';

const Game_Report = ({ PermLevel }) => {
  return (
    <Box sx={{minHeight: '100vh', width: '104vw', display: 'flex', justifyContent: 'center', backgroundColor: ''}}>
        <Typography sx={{ fontFamily: 'Impact', color: '#fff', fontSize: '50px'}}>Game_Report</Typography>
    </Box>
  )
}

export default Game_Report