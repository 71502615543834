import axios from 'axios';
import { BASE_URL } from './Constants';

export async function CreateLinkedAccount(Username, Platform, userData) {

    const Data = {
        Username,
        Platform,
        userData
    };

    const { data } = await axios.post(BASE_URL + '/profile/linkedaccounts/add', Data)
    return data;

}

export async function DeleteLinkedAccount(Item, userData) {

    const Data = {
        Item, 
        userData
    };

    const { data } = await axios.post(BASE_URL + '/profile/linkedaccounts/delete', Data);
    return data;

}

export async function clickOnAccount(item) {

    if(item.Platform === 'Steam') { 
  
        const l = item.Username.split('/')[3];
        
          if(l === 'profiles') {
            window.open(`https://rocketleague.tracker.network/rocket-league/profile/steam/${item.Username.split("/profiles/")[1].replace('/', '').replace('/', '')}/overview`, '_blank')
          } else if(l === 'id') {
            window.open(`https://rocketleague.tracker.network/rocket-league/profile/steam/${item.Username.split('/id/')[1].replace('/', '').replace('/', '')}/overview`, '_blank')
          }
                
        }
        if(item.Platform === 'Epic_Games') { window.open(`https://rocketleague.tracker.network/rocket-league/profile/epic/${item.Username}/overview`, '_blank')}
        if(item.Platform === 'PlayStation') { window.open(`https://rocketleague.tracker.network/rocket-league/profile/psn/${item.Username}/overview`, '_blank')}
        if(item.Platform === 'XBOX') { window.open(`https://rocketleague.tracker.network/rocket-league/profile/xbl/${item.Username}/overview`, '_blank')}
    
}

export async function getLinknedAccounts(userData) {

  const Data = {
    userData
  };

  const { data } = await axios.post(BASE_URL + '/profile/linkedaccounts/get', Data)
  return data;



}