import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Stack, Box, Typography, Badge, Slide } from '@mui/material';
import { authorizeUser } from '../utils/supabase';
import { Teams } from '../components/Tourney';

const TeamsDashboard = ({ userData }) => {

  const [TeamsData, setTeamsData] = useState([
    {
      ID: 'Team1',
      Name: 'Team 1',
      Logo: 'https://i.imgur.com/Ihbag0e.png'
    },
    {
      ID: 'Team1',
      Name: 'Team 1',
      Logo: 'https://i.imgur.com/Ihbag0e.png'
    },
    {
      ID: 'Team1',
      Name: 'Team 1',
      Logo: 'https://i.imgur.com/Ihbag0e.png'
    },
    {
      ID: 'Team1',
      Name: 'Team 1',
      Logo: 'https://i.imgur.com/Ihbag0e.png'
    },
    {
      ID: 'Team1',
      Name: 'Team 1',
      Logo: 'https://i.imgur.com/Ihbag0e.png'
    },
    {
      ID: 'Team1',
      Name: 'Team 1',
      Logo: 'https://i.imgur.com/Ihbag0e.png'
    },
    {
      ID: 'Team1',
      Name: 'Team 1',
      Logo: 'https://i.imgur.com/Ihbag0e.png'
    },
    {
      ID: 'Team1',
      Name: 'Team 1',
      Logo: 'https://i.imgur.com/Ihbag0e.png'
    },
    {
      ID: 'Team1',
      Name: 'Team 1',
      Logo: 'https://i.imgur.com/Ihbag0e.png'
    },
    {
      ID: 'Team1',
      Name: 'Team 1',
      Logo: 'https://i.imgur.com/Ihbag0e.png'
    },
    {
      ID: 'Team1',
      Name: 'Team 1',
      Logo: 'https://i.imgur.com/Ihbag0e.png'
    },
    {
      ID: 'Team1',
      Name: 'Team 1',
      Logo: 'https://i.imgur.com/Ihbag0e.png'
    }
  ])

  const [ViewInvites, setViewInvites] = useState(false)

  return (
    <Stack sx={{maxHeight: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', paddingTop: { xs: '60px', md: 0} }} direction="row">

    <Box sx={{ width: '63vw', minHeight: '100vh', backgroundColor: '', display: 'flex', justifyContent: 'center', textAlign: 'center', position: 'absolute', top: { xs: 50, md: 0}, left: { xs: 0, md: 80}}}>

   <Stack sx={{ width: '70vw', minHeight: '100vh', display: 'flex', alignItems: 'center', textAlign: 'center', overflow: 'scroll', backgroundColor: '' }}>
    <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '4vw', color: '#fff'}}>TEAM DASHBOARD</Typography>
    <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '3vw', color: '#fff', width: '25vw', height: '7vw', outline: '1px solid blue', backgroundColor: '#00032b', borderRadius: '25px', ":hover": { cursor: 'pointer', opacity: '0.8'}, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>CREATE A TEAM</Typography>
    
    <Box sx={{ paddingTop: '50px'}}>
    <Teams Teams={TeamsData} TeamDetails={true}/>
    </Box>

    </Stack>


    </Box>

    <Box sx={{ width: '34vw', minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: '25px', position: 'fixed', top: { xs: 50, md: 0}, right: 0}}>



      <Box sx={{ width: '30vw', minHeight: '95vh', outline: '10px dashed blue', borderRadius: '25px', display: 'flex', justifyContent: 'center', textAlign: 'center', backgroundColor: '#00032b'}}>
        
          {ViewInvites === false &&
          <Stack sx={{ width: '30vw', minHeight: '95vh', display: 'flex', alignItems: 'center'}} gap={10}>
            <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '4vw', color: '#fff'}}>LOOKING TO JOIN A TEAM?</Typography>

            <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '2vw', color: '#fff', padding: '10px'}}>VIEW YOUR INVITES AND SEE WHAT TEAMS WANT TO COMPETE WITH YOU UNDER THEIR ROSTER!</Typography>
            
            {userData.userID&&
            <Box sx={{ width: '20vw', height: '10vw', display: 'flex', alignItems: 'center', justifyContent: 'center', outline: '1px solid blue', backgroundColor: '#00032b', borderRadius: '25px', ":hover": { cursor: 'pointer', opacity: '0.8'}}} onClick={() => setViewInvites(true)}>
              <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '3vw', color: '#fff'}}>VIEW INVITES</Typography>
            </Box>}
            {!userData.userID &&
            <Box sx={{ width: '20vw', height: '10vw', display: 'flex', alignItems: 'center', justifyContent: 'center', outline: '1px solid blue', backgroundColor: '#00032b', borderRadius: '25px', ":hover": { cursor: 'pointer', opacity: '0.8'}}} onClick={() => authorizeUser()}>
              <Typography sx={{ fontFamily: 'Boomer Tantrum', fontSize: '3vw', color: '#fff'}}>SIGN IN</Typography>
            </Box>}
          </Stack>}
          

      </Box>

    </Box>

    </Stack>
  )
}

export default TeamsDashboard