import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Stack, Box, Typography } from '@mui/material';
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { NavBar } from '../components/General';

const HomePage = ({ }) => {
  return (
  <Box sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    
  </Box>
  )
}

export default HomePage