import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, CardMedia, Button, FormControl, InputLabel, TextField, Fade, Slide, Grow, Zoom, Popover, Box, Stack, Dialog, AccordionSummary, Accordion, Backdrop, CircularProgress  } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSteam, faPlaystation, faXbox } from '@fortawesome/free-brands-svg-icons'
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CreateLinkedAccount, DeleteLinkedAccount, clickOnAccount, getLinknedAccounts } from '../../utils/LinkedAccounts';

import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import axios from 'axios';
import { BASE_URL } from '../../utils/Constants';

const Epic_Games_Icon = require('../../Assets/Epic_Games.png');

const PlayerSignup = ({ userData, TourneyData }) => {

    const [isOpen, setisOpen] = useState(false)
    const [Part, setPart] = useState(1)

    const [LinkedAccounts, setLinkedAccounts] = useState([])

    const [RenderAccounts, setRenderAccounts] = useState(false)

    const navigate = useNavigate()
    
    async function nextPart() {

        const gen = Part + 1;
        setPart(gen)
  
    }

    function refreshPage() {
        window.location.reload(false);
    }


    useEffect(() => {

        console.log(TourneyData)

        if(userData.authToken) {

            async function HandleAccounts() {

                const data = await getLinknedAccounts(userData)
                if(data.length > 0) {
                    setRenderAccounts(true)
                } else {
                    navigate('/account?fail=noaccounts')
                }

                const maped = await Promise.all(data.map(async(item, index) => {
                        
                    if(item.Platform === 'Steam') {
            
                        if(!item.Username.includes('com/')) return {
                            Platform: item.Platform,
                            Username: item.Username,
                            userID: item.userID,
                            displayName: `An error has occured!`,
                            _id: item._id
                        }
            
                        const Type = item.Username.split("com/")[1].split('/')[0];
            
                        if(Type === 'profiles') {
            
                            return {
                                Platform: item.Platform,
                                Username: item.Username,
                                userID: item.userID,
                                displayName: item.Username.split("/profiles/")[1].replace('/', '').replace('/', '').toUpperCase(),
                                _id: item._id
                            }
            
                        } else if(Type === 'id') {
            
                            return {
                                Platform: item.Platform,
                                Username: item.Username,
                                userID: item.userID,
                                displayName: item.Username.split("/id/")[1].replace('/', '').replace('/', '').toUpperCase(),
                                _id: item._id
                            }
            
                        } else {
            
                            return {
                                Platform: item.Platform,
                                Username: item.Username,
                                userID: item.userID,
                                displayName: 'An Error Has Occured!',
                                _id: item._id
                            }
            
                        }
            
            
                    } else {
            
                        return {
                            Platform: item.Platform,
                            Username: item.Username,
                            userID: item.userID,
                            displayName: item.Username,
                            _id: item._id
                        }
            
                    }
            
            
            
                }))
            
                return setLinkedAccounts(maped)
                
            }

           
            HandleAccounts()

        }

    }, [userData])

    async function SignupFunction() {
 
        const Data = {
            TourneyData,
            userData
        }

        await axios.post(BASE_URL + '/tourney/player/signup', Data)

        setTimeout(() => {
            window.location.reload(true)
        }, 1000)
        
    }

    const [AccountsCorrect, setAccountsCorrect] = useState(false)
    const [RulesCorrect, setRulesCorrect] = useState(false)

  return (

        <Box sx={{ backgroundColor: 'black', width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100, top: 0 }}>

            {Part === 1 && <Box>


            {RenderAccounts === false && 
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={RenderAccounts === false}
            >
            <CircularProgress color="inherit" />
            </Backdrop>}
            {RenderAccounts === true &&  
            <Stack sx={{width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '', overflow: 'hidden'}} gap={1}>
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}><Typography variant="h5" sx={{ color: '#fff' }}>Are These Accounts Correct?</Typography></Grow>
                <Stack sx={{ backgroundColor: '', maxHeight: '60vh', overflow: 'scroll', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '0px'}} gap={1}>
                {LinkedAccounts.map((item, index) => (

                    <Box key={index}>
                    <Box sx={{ backgroundColor: '', width:  { xs: '70vw', md: '30vw'}, height: '50px', display: 'flex', justifyContent: 'center'}}>
                    {item.Platform === "Epic_Games" && <Stack direction="row" gap={1}>
                    <img src={Epic_Games_Icon} style={{ height: '50px', paddingLeft: '2px' }} />
                    <Box sx={{ width: { xs: '70vw', md: '30vw'}, backgroundColor: 'blue', height: '50px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
                        <Typography sx={{ color: '#fff', fontFamily: 'Impact', fontSize: '30px'}}>{item.displayName.toUpperCase()}</Typography>
                    </Box>
                    </Stack>}
                    {item.Platform === "Steam" && <Stack direction="row" gap={1}>
                    <FontAwesomeIcon icon={faSteam} style={{ color: `#fff`, fontSize: '50px'}} />
                    <Box sx={{ width:  { xs: '70vw', md: '30vw'}, backgroundColor: 'blue', height: '50px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
                        <Typography sx={{ color: '#fff', fontFamily: 'Impact', fontSize: '30px'}}>{item.displayName.toUpperCase()}</Typography>
                    </Box>
                    </Stack>}
                    {item.Platform === "PlayStation" && <Stack direction="row" gap={1}>
                    <FontAwesomeIcon icon={faPlaystation} style={{ color: `#fff`, fontSize: '45px'}} />
                    <Box sx={{ width:  { xs: '70vw', md: '30vw'}, backgroundColor: 'blue', height: '50px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
                        <Typography sx={{ color: '#fff', fontFamily: 'Impact', fontSize: '30px'}}>{item.displayName.toUpperCase()}</Typography>
                    </Box>
                    </Stack>}
                    {item.Platform === "XBOX" && <Stack direction="row" gap={1}>
                    <FontAwesomeIcon icon={faXbox} style={{ color: `#fff`, fontSize: '50px'}} />
                    <Box sx={{ width:  { xs: '70vw', md: '30vw'}, backgroundColor: 'blue', height: '50px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
                        <Typography sx={{ color: '#fff', fontFamily: 'Impact', fontSize: '30px'}}>{item.displayName.toUpperCase()}</Typography>
                    </Box>
                    </Stack>}

                    </Box>
                    </Box>

                ))}
                </Stack>
                
                <Box sx={{ height: '50px', width: '100vw', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', paddingTop: '20px'}}>
                    <Stack direction="row" gap={2}>
                    <Box sx={{ height: '50px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Box sx={{ width: '50px', height: '50px', outline: '5px solid #000f9f', borderRadius: '10px', backgroundColor: `${AccountsCorrect ? '#000f9f' : ''}`, ":hover": { cursor: 'pointer' }}} onClick={() => {
                            if(AccountsCorrect === false) return setAccountsCorrect(true)
                            if(AccountsCorrect === true) return setAccountsCorrect(false)
                        }}>
                            
                        </Box>
                    </Box>
                   <Stack>
                   <Typography sx={{ color: '#fff'}}>I accept the RPS Privacy Policy.</Typography>
                   <Typography sx={{ color: '#fff'}}>And I confirm that I have provided all of my RL accounts.</Typography>
                   </Stack>
                    </Stack>
                </Box>

                <Slide direction="up" in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
                <Box>
                      <table>
                        <th>
                        <Box onClick={() => navigate('/account')} sx={{ color: '#fff', backgroundColor: '#1e2124', width: '120px', height: '60px', justifyContent: 'center', alignItems: 'center', alignContent: 'center', textAlign: 'center', display: 'flex', fontSize: '20px', "&:hover": { cursor: 'pointer'} }}>
                        Add More 
                        </Box>                        
                        </th>
                        <th>
                        {AccountsCorrect === false && <Box sx={{ color: 'black', backgroundColor: '#1e2124', width: '100px', height: '60px', justifyContent: 'center', alignItems: 'center', alignContent: 'center', textAlign: 'center', display: 'flex', fontSize: '20px', "&:hover": { cursor: 'pointer'} }}>
                        Next 
                        </Box>}
                        {AccountsCorrect === true && <Box  onClick={() => nextPart()} sx={{ color: '#fff', backgroundColor: '#1e2124', width: '100px', height: '60px', justifyContent: 'center', alignItems: 'center', alignContent: 'center', textAlign: 'center', display: 'flex', fontSize: '20px', "&:hover": { cursor: 'pointer'} }}>
                        Next 
                        </Box>}
                        </th>
                      </table>
                    </Box>
                </Slide>
            
            
            </Stack>}

            </Box>}

            {Part === 2 && <Box sx={{ }}>

            <Box>
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}><Typography variant="h5">Do You Accept The BGCS Rules?</Typography></Grow>
            </Box>

            <Box sx={{ paddingTop: '10px'}}>

            <Box>

            {TourneyData.Rules.map((item, index) => (
                <Slide in={Part === 2} direction="left" style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})} key={index}>

                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                <Typography>{item.Name}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', textAlign: 'left'}}>
               <Stack gap={2}>
               {item.Rules.map((item, index) => (
                    <Typography key={index}>
                        •{item.Text}
                    </Typography>
                ))}
               </Stack>
                </AccordionDetails>
                </Accordion>
    
                </Slide>
            ))}
            </Box>
            <Stack direction="row" gap={2} sx={{ paddingTop: '25px', paddingBottom: '20px' }}>
                    <Box sx={{ height: '50px', backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Box sx={{ width: '50px', height: '50px', outline: '5px solid #000f9f', borderRadius: '10px', backgroundColor: `${RulesCorrect ? '#000f9f' : ''}`, ":hover": { cursor: 'pointer' }}} onClick={() => {
                            if(RulesCorrect === false) return setRulesCorrect(true)
                            if(RulesCorrect === true) return setRulesCorrect(false)
                        }}>
                            
                        </Box>
                    </Box>
                   <Stack>
                   <Typography sx={{ color: '#fff'}}>I confirm I have read the rules.</Typography>
                   <Typography sx={{ color: '#fff'}}>Meaning you are able to follow them, without issue.</Typography>
                   </Stack>
            </Stack>

            <Slide direction="up" in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
            <Box>
                      <table>
                        <th>
                        <Box onClick={() => setPart(1)} sx={{ color: '#fff', backgroundColor: '#1e2124', width: '120px', height: '60px', justifyContent: 'center', alignItems: 'center', alignContent: 'center', textAlign: 'center', display: 'flex', fontSize: '20px', "&:hover": { cursor: 'pointer'} }}>
                        Go Back
                        </Box>                        
                        </th>
                        <th>
                        {RulesCorrect === false && <Box sx={{ color: 'black', backgroundColor: '#1e2124', width: '100px', height: '60px', justifyContent: 'center', alignItems: 'center', alignContent: 'center', textAlign: 'center', display: 'flex', fontSize: '20px', "&:hover": { cursor: 'pointer'} }}>
                        Signup 
                        </Box>}
                        {RulesCorrect === true && <Box  onClick={() => {
                            nextPart()
                            SignupFunction()
                        }} sx={{ color: '#fff', backgroundColor: '#1e2124', width: '100px', height: '60px', justifyContent: 'center', alignItems: 'center', alignContent: 'center', textAlign: 'center', display: 'flex', fontSize: '20px', "&:hover": { cursor: 'pointer'} }}>
                        Signup 
                        </Box>}
                        </th>
                      </table>
                    </Box>
                    </Slide>
            
            </Box>

            </Box>}
            
            {Part === 3 && <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={Part === 3}
            >
            <CircularProgress color="inherit" />
            </Backdrop>}
        
        </Box>
  )
}

export default PlayerSignup