import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, CardMedia, Button, FormControl, InputLabel, TextField, Fade, Slide, Grow, Zoom, Popover, Box, Stack  } from '@mui/material';
import { LinkedAccounts, Settings } from '../components/Account';
import queryString from 'query-string';

const Account = ({ userData }) => {

    const { fail } = queryString.parse(window.location.search);

    const [Theme, setTheme] = useState({
        backgroundColor: 'black',
        BorderColor: 'blue',
        TextColor: '#fff'
    })

    const [MenuValue, setMenuValue] = useState(1)
    const [MenuItems, setMenuItems] = useState([
        {
            name: 'Linked Accounts',
            Page: <LinkedAccounts Theme={Theme} userData={userData} fail={fail} />
        },
        {
            name: 'Social Accounts',
            Page: <LinkedAccounts Theme={Theme} userData={userData} />
        },
        {
            name: 'Statistics Total',
            Page: <LinkedAccounts Theme={Theme}userData={userData} />
        },
        {
            name: 'Statistics Average',
            Page: <LinkedAccounts Theme={Theme} userData={userData} />
        },
        {
            name: 'Settings',
            Page: <Settings Theme={Theme} userData={userData} />
        }
    ])

  return (
    <Box sx={{ width: '100vw', height: { xs: '120vh', md: '100vh'}, backgroundColor: `${Theme.backgroundColor}` }}>

        <Box sx={{ height: { xs: '120vh', md: '100vh'}, width: '100vw', display: 'flex', justifyContent: 'center', paddingTop: { xs: '70px', md: '50px'}}}>
            
            <Stack gap={5}>
            <Stack direction="row" gap={15}>
            <Box sx={{ height: '200px', width: '200px', outline: `5px solid ${Theme.BorderColor}`, borderRadius: '25px', backgroundColor: 'Black',backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${userData.profile.avatar})`}}>
                
            </Box>

                <Box sx={{ height: '200px', width: '50vw', outline: `5px solid ${Theme.BorderColor}`, borderRadius: '25px', backgroundColor: 'Black',backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${userData.profile.Banner})`, display: { xs: 'none', md: 'unset'}}}>
                    {/* <Stack gap={7} sx={{ paddingLeft: '10px'}}>
                        <Box sx={{ width: '50vw', height: '100px', backgroundColor: '', borderTopLeftRadius: '25px', borderTopRightRadius: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography sx={{ fontFamily: 'Impact', fontSize: '4vw', color: `${Theme.TextColor}`, textShadow: '5px 5px 0px blue' }}>PLAYER NAME</Typography>
                        </Box>
                        <Box sx={{ width: '50vw', height: '20px', backgroundColor: '', borderBottomLeftRadius: '25px', borderBottomRightRadius: '25px', display: 'flex' }}>
                            <Stack direction="row" gap={2}>
                                <Box sx={{ height: '40px', width: '150px', backgroundColor: 'gold', borderRadius: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: '0.8', outline: '2px solid blue'}}>
                                    <Typography sx={{ fontFamily: 'Impact', fontSize: '30px' }}>FOUNDER</Typography>
                                </Box>
                                <Box sx={{ height: '40px', width: '150px', backgroundColor: 'gold', borderRadius: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: '0.8', outline: '2px solid blue'}}>
                                    <Typography sx={{ fontFamily: 'Impact', fontSize: '30px' }}>ADMIN</Typography>
                                </Box>
                            </Stack>
                        </Box>
                    </Stack> */}
                </Box>
            </Stack>

            <Stack direction="row" gap={15}>
                <Box sx={{ height: '635px', width: '200px', outline: `5px solid ${Theme.BorderColor}`, borderRadius: '25px', backgroundColor: 'Black', overflow: 'clip', display: { xs: 'none', md: 'unset' }}}>
                    <Stack sx={{ overflow: 'clip'}}>
                        <Box sx={{ height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '5px solid blue', backgroundColor: 'blue', borderTopRightRadius: '24px', borderTopLeftRadius: '24px'}}>
                            <Typography sx={{ fontFamily: 'Impact', color: `${Theme.TextColor}`, fontSize: '40px'}}>MENU</Typography>
                        </Box>
                        {MenuItems.map((item, index) => (
                       
                            <Box>

                            {MenuValue === index + 1 &&  <Box sx={{ height: '110px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '5px solid blue', textAlign: 'center', backgroundColor: 'darkblue'}} key={index}>
                            <Typography sx={{ fontFamily: 'Impact', color: `${Theme.TextColor}`, fontSize: '40px'}}>{item.name.toUpperCase()}</Typography>
                            </Box>}

                            {MenuValue != index + 1 &&  <Box sx={{ height: '110px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '5px solid blue', textAlign: 'center', backgroundColor: 'blue', color: `${Theme.TextColor}`, ":hover": { color: 'black', cursor: 'pointer', textShadow: '1px 1px 0px #fff' }}} key={index} onClick={() => setMenuValue(index + 1)}>
                            <Typography sx={{ fontFamily: 'Impact', fontSize: '40px'}}>{item.name.toUpperCase()}</Typography>
                            </Box>}
                                
                            </Box>

                        ))}
                        
                    </Stack>
                </Box>

                <Box sx={{ height: '635px', width: { xs: '90vw', md: '50vw'}, outline: `5px solid ${Theme.BorderColor}`, borderRadius: { xs: '0', md: '25px'}, backgroundColor: 'Black', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {MenuItems[MenuValue - 1].Page}
                </Box>
            </Stack>
            
            </Stack>

            
        </Box>

    </Box>
  )
}

export default Account