import axios from 'axios';
import { BASE_URL } from './Constants';

export async function updateUsername(Data) {

    const data = await axios.post(BASE_URL + '/profile/update/username', Data)
    return data
    
}

export async function updatePFP(Data) {

    const data = await axios.post(BASE_URL + '/profile/update/pfp', Data)
    return data
    
}

export async function updateBanner(Data) {

    const data = await axios.post(BASE_URL + '/profile/update/banner', Data)
    return data
    
}